import React from 'react';
import { useEffect, useState } from 'react';
import { _$http, axiosError, closeAlert, preloader } from '../../utils';
import { ThreeDots, XMark } from '../../components/Icons';
import { formatDateTime, tinyAlert } from '../../services/_config.services';

function Roles() {
  const [toggleCreateModal, setToggleCreateModal] = useState(false)
  const [toggleEditModal, setToggleEditModal] = useState(false)
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [formData, setFormData] = useState([])
  const [formInputs, setFormInputs] = useState({
    name: "", code: "", canExtendWorkflow: '', type: ''
  })
  const [roleId, setRoleId] = useState(null)
  useEffect(() => { getRoles() }, []);

  const getRoles = () => {
    preloader();
    _$http('GET', 'Roles').then(res => {
      closeAlert();
      setFormData(res.data.data);
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormInputs({ ...formInputs, [name]: value })
  }

  const handleEditFields = (item: any) => {
    setFormInputs({ ...formInputs, name: item.name, canExtendWorkflow: item.canExtendWorkflow, type:item.type  })
    setRoleId(item.id);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    preloader();

    const data = {
      RoleName:formInputs.name,
      Type:formInputs.type,
      CanExtendWorkflow: formInputs.canExtendWorkflow === 'yes' ? true : false,
    }
    
    _$http('POST', 'Roles', data).then(res => {
      closeAlert();
      setToggleCreateModal(false)
      tinyAlert('success', res.data.message)
      getRoles()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();

    console.log(formInputs.canExtendWorkflow);
    
    const data = {
      RoleName:formInputs.name,
      Type:formInputs.type,
      CanExtendWorkflow: formInputs.canExtendWorkflow === 'yes' ? true : false,
    }

    preloader();
    _$http('PUT', 'Roles?roleId=' + roleId, data).then(res => {
      closeAlert();
      setToggleEditModal(false)
      setRoleId(null)
      tinyAlert('success', res.data.message)
      getRoles()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleDelete = () => {
    preloader();
    setToggleConfirmationModal(false)
    _$http('DELETE', 'Roles?roleId=' + roleId).then(res => {
      closeAlert();
      setRoleId(null)
      const message = res.data.message
      console.log(message)
      tinyAlert('success', message)
      getRoles()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-6'>
      <div className='flex justify-end px-6'>
        {/* <input type="text" name='search' className="border px-3 py-2 text-sm mr-1" placeholder="Search Roles" /> */}
        <button onClick={() => setToggleCreateModal(true)} className='red-button rounded px-4 py-2 text-sm'>Create Role</button>
      </div>

      <div className="relative overflow-x-auto py-4" style={{ minHeight: "600px" }}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 notdark:text-gray-400">
          <thead className="text-[14px] text-gray-700 uppercase bg-gray-50 notdark:bg-gray-700 notdark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                SN
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Workflow Extension
              </th>
              <th scope="col" className="px-6 py-3">
                Created Date
              </th>
              <th scope="col" className="px-6 py-3">
                Updated Date
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {
              formData.map((item: any, index: number) => {
                return (
                  <tr key={index} className="bg-white border-b notdark:bg-gray-800 notdark:border-gray-700">
                    <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                      {item.name}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                      {item.type}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                      {item.canExtendWorkflow ? 'Yes' : 'No'}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                        {formatDateTime(item.createdDate)}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                        {formatDateTime(item.updatedDate)}
                    </th>
                    <td className="px-6 py-4">
                      <div className="dropdown">
                        <button title='More Options' className='text-blue-600 dropbtn'>
                          <ThreeDots />
                        </button>
                        <div className="dropdown-content" style={{ left: "0" }}>
                          <button onClick={() => { setToggleEditModal(true); handleEditFields(item) }}>Edit</button>
                          <button onClick={() => { setToggleConfirmationModal(true); setRoleId(item.id) }}>Delete </button>
                          {/* <button>Manage Permissions</button> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {
        toggleCreateModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Create Role</h2>
              <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Role</label>
                <input onChange={handleInputChange} type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" />
              </div>

              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Type</label>

                <select  onChange={handleInputChange} value={formInputs.type} name='type' className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" >
                  <option value={''} selected >Select option</option>
                  <option value={3}>Departmental</option>
                  <option value={2}>Global</option>
                  <option value={1}>General</option>
                </select>
              </div>

              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Extend Form</label>

                <select onChange={handleInputChange} name='canExtendWorkflow'  className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" >
                  <option value={''} >Select option</option>
                  <option value={'yes'} >Yes</option>
                  <option value={'no'} >No</option>
                </select>
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 notdark:bg-blue-600 notdark:hover:bg-blue-700 focus:outline-none notdark:focus:ring-blue-800">Submit</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleEditModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Edit Role</h2>
              <button onClick={() => setToggleEditModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleUpdate}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Role</label>
                <input onChange={handleInputChange} value={formInputs.name} type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" />
              </div>

              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Type</label>

                <select  onChange={handleInputChange} value={formInputs.type} name='type' className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" >
                  <option value={''} selected >Select option</option>
                  <option value={3}>Departmental</option>
                  <option value={2}>Global</option>
                  <option value={1}>General</option>
                </select>
              </div>

              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Extend Form</label>

                <select onChange={handleInputChange} name='canExtendWorkflow'  className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" >
                  <option value={''} >Select option</option>
                  <option value={'yes'} >Yes</option>
                  <option value={'no'} >No</option>
                </select>
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 notdark:bg-blue-600 notdark:hover:bg-blue-700 focus:outline-none notdark:focus:ring-blue-800">Update</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleConfirmationModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-md">Are you sure you want to delete this role?</h2>
              <button onClick={() => setToggleConfirmationModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <div className="modal-content text-left px-6 flex justify-center gap-2 mt-4">
              <button onClick={() => setToggleConfirmationModal(false)} type="button" className="rounded text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 notdark:bg-gray-800 notdark:hover:bg-gray-700 notdark:focus:ring-gray-700 notdark:border-gray-700">Cancel</button>
              <button onClick={() => handleDelete()} type="button" className="rounded text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 notdark:bg-blue-600 notdark:hover:bg-blue-700 focus:outline-none notdark:focus:ring-blue-800">Yes</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Roles;
