import { useState, useEffect, useCallback, FormEvent, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  OptionProps,
  FormWorkflow,
  FormApproval,
  FormDetails,
  RoutingSlipForDeviationFromStandardTemplateFormInputs,
  RoutingSlipForDeviationFromStandardTemplateFormSelects,
} from "../../../interfaces/forms.interface";
import { Arsterisks, XMark } from "../../../components/Icons";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { formTypesID } from "../../../utils/data";
import api from "../../../services/form.services";
import routingSlipForDeviationFromStandardTemplateFormService
  from "../../../services/forms/RoutingSlipForDeviationFromStandardTemplateForm.services";
import FormDescription from "../../../components/FormDescription";
import FormApprovalOverlay from "../../../components/FormApprovalOverlay";
import { Label } from "../../../components/FormUI";
import ApprovalSequenceFlow from "../../../components/ApprovalSequenceFlow";
import form_util from "../../../services/FormUtil.services";
import { CheckValidationKeys, form_input_style, tinyAlert } from "../../../utils";
import { User } from "../../../interfaces/users.interface";
import { useSelector } from "react-redux";
import FormNotes from "../../../components/FormNotes";

const filterOptions = createFilterOptions({ limit: 100 });

const getOptionLabel = (option: any) => (<div dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : '' }} />);

function RoutingSlipForDeviationStandardTemplate() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const user: User = useSelector((state: any) => state.tepngUser.value.user);
  const formIDQuery: any = params.id;
  const formName: string = location.pathname.split("/")[4];
  const formType: any = formTypesID.find((x: any) => x.name === formName)?.type;

  // set the form inputs
  const defaultInputs: RoutingSlipForDeviationFromStandardTemplateFormInputs = {
    Description: "",
    AlternateEmailAddress: "",
    IsDraft: false,
    Justification: "",
    NameOfBuyer: `${user.firstName} ${user.lastName}`,
    OthersDescription: "",
  };

  const requiredInputs = ['Description'];
  const requiredSelect = ['validated_by_catman', 'department', 'template_reviewed_and_updated_by_insurance', 'template_reviewed_and_updated_by_legal', 'template_reviewed_and_updated_by_tax', 'template_reviewed_and_updated_by_cost_control', 'template_reviewed_and_updated_by_compliance', 'template_reviewed_and_updated_by_hse', 'assets', 'linked_to_category'];

  // set up the default selects for the form
  const defaultSelects: RoutingSlipForDeviationFromStandardTemplateFormSelects =
  {
    department: { label: "Select...", value: null, name: "department" },
    assets: { label: "Select...", value: null, name: "assets" },
    linked_to_category: {
      label: "Select...",
      value: null,
      name: "linked_to_category",
    },
    validated_by_catman: {
      label: "Select...",
      value: null,
      name: "validated_by_catman",
    },
    template_reviewed_and_updated_by_insurance: {
      label: "Select...",
      value: null,
      name: "template_reviewed_and_updated_by_insurance",
    },
    template_reviewed_and_updated_by_legal: {
      label: "Select...",
      value: null,
      name: "template_reviewed_and_updated_by_legal",
    },
    template_reviewed_and_updated_by_tax: {
      label: "Select...",
      value: null,
      name: "template_reviewed_and_updated_by_tax",
    },
    template_reviewed_and_updated_by_cost_control: {
      label: "Select...",
      value: null,
      name: "template_reviewed_and_updated_by_cost_control",
    },
    template_reviewed_and_updated_by_compliance: {
      label: "Select...",
      value: null,
      name: "template_reviewed_and_updated_by_compliance",
    },
    template_reviewed_and_updated_by_hse: {
      label: "Select...",
      value: null,
      name: "template_reviewed_and_updated_by_hse",
    },
    selected_attachment_type: {
      label: "Select...",
      value: null,
      name: "selected_attachment_type",
    },
  };

  // page data default states.
  const [isChecked, setbuttonChecked] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<string>("DRAFT");
  const [parentFormId, setParentFormId] = useState<string>("");
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [linked_to_category, setLinkedToCategory] = useState<OptionProps[]>([]);
  const [assets, setAssets] = useState<OptionProps[]>([]);
  const [validated_by_catman, setValidatedByCatman] = useState<OptionProps[]>([]);
  const [template_reviewed_and_updated_by_insurance, setTemplateReviewedAndUpdatedByInsurance] = useState<OptionProps[]>(
    []
  );
  const [template_reviewed_and_updated_by_legal, setTemplateReviewedAndUpdatedByLegal] = useState<OptionProps[]>(
    []
  );
  const [template_reviewed_and_updated_by_tax, setTemplateReviewedAndUpdatedByTax] = useState<OptionProps[]>(
    []
  );
  const [template_reviewed_and_updated_by_cost_control, setTemplateReviewedAndUpdatedByCostControl] = useState<OptionProps[]>(
    []
  );
  const [template_reviewed_and_updated_by_compliance, setTemplateReviewedAndUpdatedByCostCompliance] = useState<OptionProps[]>(
    []
  );
  const [template_reviewed_and_updated_by_hse, setTemplateReviewedAndUpdatedByHSE] = useState<OptionProps[]>(
    []
  );
  const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
  const [attachment_type, setAttachmentType] = useState<OptionProps[]>([]);
  const [formDescription, setFormDescription] = useState<FormDetails>();
  const [form_workflow, setFormWorkflow] = useState<FormWorkflow>();
  const [form_approval, setFormApproval] = useState<FormApproval[]>([]);
  const [formDocuments, setFormDocuments] = useState<any>([]);
  const [removedDocumentIds, setRemovedDocumentIds] = useState<any>([]);
  const [formInputs, setFormInputs] =
    useState<RoutingSlipForDeviationFromStandardTemplateFormInputs>(
      defaultInputs
    );
  const [formSelects, setFormSelects] =
    useState<RoutingSlipForDeviationFromStandardTemplateFormSelects>(
      defaultSelects
    );
  const [togggleModal, setModalTogggle] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<
    string[]
  >([]);
  const clickedButtonRef = useRef("");

  useEffect(() => { api.fetchFormDescription(formName).then((res) => setFormDescription(res)); }, [formName]);
  useEffect(() => { getFormById(formIDQuery, user, navigate); }, [formIDQuery, user, navigate]);

  useEffect(() => {
    api.fetchDepartment().then((res) => { setDepartment(res) });

    api.fetchAssets().then((res) => setAssets(res));

    api.fetchYesNoNotApplicable().then((res) => {
      setLinkedToCategory(res);
      setValidatedByCatman(res);
      setTemplateReviewedAndUpdatedByInsurance(res);
      setTemplateReviewedAndUpdatedByInsurance(res);
      setTemplateReviewedAndUpdatedByLegal(res);
      setTemplateReviewedAndUpdatedByTax(res);
      setTemplateReviewedAndUpdatedByCostControl(res);
      setTemplateReviewedAndUpdatedByCostCompliance(res);
      setTemplateReviewedAndUpdatedByHSE(res);
    });
    api.fetchTepngUsers().then((res) => setTepngUsers(res));
  }, []);

  useEffect(() => {
    api.fetchFormAttachmentTypes(formType).then((res) => setAttachmentType(res));
    api.fetchFormWorkFlow(formType).then((res) => setFormWorkflow(res));
  }, [formType]);

  useEffect(() => {
    if (!!department.length) {
      let def_department: any = department.find(
        (a: any) => a.value === user.departmentId
      );
      if (!!def_department)
        formSelects.department = def_department;
    }

    
    // eslint-disable-next-line 
  }, [department]);



  useEffect(() => {
    const data = {
      department: formSelects.department,
      form_workflow: form_workflow
    }

    form_util.currencyThresholds(data);

  }, [formSelects.department, form_workflow]);

  // fetch-approval-sequence
  useEffect(() => {
    const data = {
      DepartmentId: formSelects.department.value
    }

    if (!!data.DepartmentId) {
      routingSlipForDeviationFromStandardTemplateFormService.fetchApprovalSequence(data)
        .then((res) => {
          if (!!res?.length) {
            if (form_approval.length !== res.length) {
              setFormApproval(res);
            }
          }
        });

    }
  }, [
    formSelects.department,
    form_approval,
  ]);

  const getFormById = (formIDQuery: string, user:User, navigate:any) => {
    if (formIDQuery) {
      routingSlipForDeviationFromStandardTemplateFormService.fetchFormById(formIDQuery)
        .then((res) => {
          if (res) {
            setFormInputs({
              Description: res.description,
              AlternateEmailAddress: res.alternateEmailAddress ?? "",
              IsDraft: res.isDraft,
              Justification: res.justification,
              NameOfBuyer: user.firstName + " " + user.lastName,
              OthersDescription: res.othersDescription
            });

            setFormSelects({
              department: {
                label: res.department.name,
                value: res.department.id,
                name: "department"
              },
              assets: {
                label: res.assetDetails.name,
                value: res.assetDetails.id,
                name: "assets",
              },

              linked_to_category: {
                label: res.linkedToCategoryDetails.name,
                value: res.linkedToCategoryDetails.id,
                name: "linked_to_category",
              },
              validated_by_catman: {
                label: res.validatedByCATMANDetails.name,
                value: res.validatedByCATMANDetails.id,
                name: "validated_by_catman",
              },
              template_reviewed_and_updated_by_insurance: {
                label: res.updatedByInsuranceDetails.name,
                value: res.updatedByInsuranceDetails.id,
                name: "template_reviewed_and_updated_by_insurance",
              },
              template_reviewed_and_updated_by_legal: {
                label: res.updatedByLegalDetails.name,
                value: res.updatedByLegalDetails.id,
                name: "template_reviewed_and_updated_by_legal",
              },
              template_reviewed_and_updated_by_tax: {
                label: res.updatedByTaxDetails.name,
                value: res.updatedByTaxDetails.id,
                name: "template_reviewed_and_updated_by_tax",
              },
              template_reviewed_and_updated_by_cost_control: {
                label: res.updatedByFinanceDetails.name,
                value: res.updatedByFinanceDetails.id,
                name: "template_reviewed_and_updated_by_cost_control",
              },
              template_reviewed_and_updated_by_compliance: {
                label: res.updatedByComplianceDetails.name,
                value: res.updatedByComplianceDetails.id,
                name: "template_reviewed_and_updated_by_compliance",
              },
              template_reviewed_and_updated_by_hse: {
                label: res.updatedByHSEDetails.name,
                value: res.updatedByHSEDetails.id,
                name: "template_reviewed_and_updated_by_hse",
              },
              selected_attachment_type: { label: 'Select...', value: '', name: 'selected_attachment_type' },
            });

            const approval_sort = res.formApprovers.sort((a:any, b:any) => a.step - b.step);
            const approvals: any[] = approval_sort.map((i: any) => ({
                email: i.email,
                roleId: i.roleId,
                roleName: i.role.name,
            }));
    
            // let currentStatus = ''
            // for(let i = 0;  i < approval_sort.length; i++){
            //     if(approval_sort[i].formApproval){
            //         currentStatus = approval_sort[i]?.formApproval?.status
            //     }
            // }

            // if(currentStatus === 'APPROVED'){
            //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
            //     navigate("/forms/history/cpnc-routing-slip");
            //     return;
            // }

            // if(res.currentStatus !== 'RESTART' && res.currentStatus !== 'DRAFT'){
            //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
            //     navigate("/forms/history/cpnc-routing-slip");
            //     return;
            // }

            if(res.canEditOrDelete !== true && res.currentStatus !== "RESTART"){
              tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
              navigate("/initiator/forms/history/cpnc-routing-slip");
              return;
          }
          
            
            setFormApproval(approvals);
            setFormDocuments(res.documents);
            setCurrentStatus(res.currentStatus);
            setParentFormId(res.parentFormId);
          }
        });
    }
  };

  // form-hanlders
  const handleInput = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInputs({ ...formInputs, [key]: e.target.value });
  };

  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormSelects({ ...formSelects, [fieldName]: selectedOptions });
  };

  const handleModal = () => {
    if (togggleModal) {
      setModalTogggle(false);
    } else setModalTogggle(true);
  };

  // !!! worry about the variable names! very important.
  const handleFormApproval = (el: any, option: any, item: any) => {
    const new_form_approvals = form_approval.map((i: any) => {
      i.email = i.roleId === item.roleId ? option?.value : i.email;
      return i;
    });
    setFormApproval(new_form_approvals);
  };

  const handleAlternateEmailAddress = (el: any, option: any) => {
    setFormInputs({ ...formInputs, AlternateEmailAddress: option.value });
  };

  // file-local-upload
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newAttachTypeLabel =
        formSelects.selected_attachment_type.label === "Others"
          ? formInputs.OthersDescription
          : formSelects.selected_attachment_type.label;

      let uploadType = uploadedFilesAttachType;

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadType.push(newAttachTypeLabel);
      }

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...acceptedFiles,
      ]);
      setUploadedFilesAttachType(uploadType);
    },
    [
      formSelects.selected_attachment_type.label,
      formInputs.OthersDescription,
      uploadedFilesAttachType,
    ]
  );

  // remove attached file.
  const removeFile = (index: number) => {
    const updatedNames = [...uploadedFilesAttachType];
    updatedNames.splice(index, 1);

    const updated_files = [...uploadedFiles];
    updated_files.splice(index, 1);

    setUploadedFiles(updated_files);
    setUploadedFilesAttachType(updatedNames);
  };

  // remove attached file - when status is update form
  const removePrevFile = (index: number) => {
    const updated_files = [...formDocuments];
    const deletedFile = updated_files.splice(index, 1);
    setFormDocuments(updated_files);

    const removed_files = [...removedDocumentIds];
    removed_files.push(deletedFile[0].id);
    setRemovedDocumentIds(removed_files);

  };

  const { getRootProps, getInputProps } = useDropzone({
    //accept: ['.png', '.jpg', '.jpeg', '.gif'],//'image/*', // Specify the file types you want to allow
    onDrop,
  });

  // !!! submit form function;
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const data: any = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      IsDraft: clickedButtonRef?.current === "post" ? false : true,
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
    }



    // [validation 1] check for required input-fields here;
    const emptyRequiredInputs = Object.keys(data.formInputs).filter(key => data.formInputs[key] === "");
    const emptyRequiredSelects = Object.keys(data.formSelects).filter(key => {
      return !(data.formSelects[key]?.value === undefined ? data.formSelects[key] : data.formSelects[key]?.value);
    });

    let missingItems = '';
    missingItems += CheckValidationKeys(requiredInputs, emptyRequiredInputs);
    missingItems += CheckValidationKeys(requiredSelect, emptyRequiredSelects);

    if (missingItems) {
      tinyAlert('error', 'Complete all required field: ' + missingItems);
      return;
    }

    // [validation 2] validate alternative email && user emails
    if (user.email === data.formInputs?.AlternateEmailAddress) {
      tinyAlert('error', 'You cannot use yourself as the alternative email address.');
      return;
    }

    // check if approval sequence is not all empty
    const form_approval_emails = form_approval.every((obj: any) => obj.email.trim() !== '');
    if (!form_approval_emails) {
      tinyAlert('error', 'Some or all emails in approval sequence are empty.');
      return;
    }

    const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === data.formInputs?.AlternateEmailAddress));
    console.log(invalid_emails);

    if (invalid_emails.length > 0) {
      tinyAlert('error', 'Some or all emails in approval sequence cannot have your email address or alternative email address.');
      return;
    }

    // [validation 3] check if approval sequence does not have same email address;
    const emails = form_approval.map((obj: any) => obj.email);
    const uniqueEmails = new Set(emails);
    if (emails.length !== uniqueEmails.size) {
      tinyAlert('error', 'Approval sequence with the same approval emails are not allowed!.');
      return;
    }

    routingSlipForDeviationFromStandardTemplateFormService.setForm(data).then((res: any) => {
      if (!!res?.id) navigate("/initiator/forms/history/routing-slip-for-deviation-from-standard-template");
    });
  };

  const handleUpdate = (e: FormEvent, action: string) => {
    e.preventDefault();

    const data: any = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      removedDocumentIds: removedDocumentIds,
      formIDQuery: formIDQuery,
      IsDraft: action === "update_and_submit" ? false : true,
    }



    // [validation 1] check for required input-fields here;
    const emptyRequiredInputs = Object.keys(data.formInputs).filter(key => data.formInputs[key] === "");
    const emptyRequiredSelects = Object.keys(data.formSelects).filter(key => {
      return !(data.formSelects[key]?.value === undefined ? data.formSelects[key] : data.formSelects[key]?.value);
    });

    let missingItems = '';
    missingItems += CheckValidationKeys(requiredInputs, emptyRequiredInputs);
    missingItems += CheckValidationKeys(requiredSelect, emptyRequiredSelects);

    if (missingItems) {
      tinyAlert('error', 'Complete all required field: ' + missingItems);
      return;
    }

    // [validation 2] validate alternative email && user emails
    if (user.email === data.formInputs?.AlternateEmailAddress) {
      tinyAlert('error', 'You cannot use yourself as the alternative email address.');
      return;
    }

    // check if approval sequence is not all empty
    const form_approval_emails = form_approval.every((obj: any) => obj.email.trim() !== '');
    if (!form_approval_emails) {
      tinyAlert('error', 'Some or all emails in approval sequence are empty.');
      return;
    }

    const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === data.formInputs?.AlternateEmailAddress));
    console.log(invalid_emails);

    if (invalid_emails.length > 0) {
      tinyAlert('error', 'Some or all emails in approval sequence cannot have your email address or alternative email address.');
      return;
    }

    // [validation 3] check if approval sequence does not have same email address;
    const emails = form_approval.map((obj: any) => obj.email);
    const uniqueEmails = new Set(emails);
    if (emails.length !== uniqueEmails.size) {
      tinyAlert('error', 'Approval sequence with the same approval emails are not allowed!.');
      return;
    }

    routingSlipForDeviationFromStandardTemplateFormService.updateForm(data)
      .then((res) => {
            if (!!res?.id) navigate("/initiator/forms/history/routing-slip-for-deviation-from-standard-template");
        // if (action === "update_and_submit") {
        //   routingSlipForDeviationFromStandardTemplateFormService.submitDraft(formIDQuery).then((res) => {
        //   });
        // }
      });
  };

  const handleReview = (e: FormEvent) => {
    e.preventDefault();
    const data: any = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      formIDQuery: formIDQuery,
      parentFormId: !!parentFormId ? parentFormId : formIDQuery,
    }



    // [validation 1] check for required input-fields here;
    const emptyRequiredInputs = Object.keys(data.formInputs).filter(key => data.formInputs[key] === "");
    const emptyRequiredSelects = Object.keys(data.formSelects).filter(key => {
      return !(data.formSelects[key]?.value === undefined ? data.formSelects[key] : data.formSelects[key]?.value);
    });

    let missingItems = '';
    missingItems += CheckValidationKeys(requiredInputs, emptyRequiredInputs);
    missingItems += CheckValidationKeys(requiredSelect, emptyRequiredSelects);

    if (missingItems) {
      tinyAlert('error', 'Complete all required field: ' + missingItems);
      return;
    }

    // [validation 2] validate alternative email && user emails
    if (user.email === data.formInputs?.AlternateEmailAddress) {
      tinyAlert('error', 'You cannot use yourself as the alternative email address.');
      return;
    }

    // check if approval sequence is not all empty
    const form_approval_emails = form_approval.every((obj: any) => obj.email.trim() !== '');
    if (!form_approval_emails) {
      tinyAlert('error', 'Some or all emails in approval sequence are empty.');
      return;
    }

    const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === data.formInputs?.AlternateEmailAddress));
    console.log(invalid_emails);

    if (invalid_emails.length > 0) {
      tinyAlert('error', 'Some or all emails in approval sequence cannot have your email address or alternative email address.');
      return;
    }

    // [validation 3] check if approval sequence does not have same email address;
    const emails = form_approval.map((obj: any) => obj.email);
    const uniqueEmails = new Set(emails);
    if (emails.length !== uniqueEmails.size) {
      tinyAlert('error', 'Approval sequence with the same approval emails are not allowed!.');
      return;
    }

    routingSlipForDeviationFromStandardTemplateFormService.reviewForm(data)
      .then((res: any) => {
        if (!!res?.id) navigate("/initiator/forms/history/routing-slip-for-deviation-from-standard-template");
      });
  };

  // !!! VIEWS
  return (
    <div className="bg-white w-full shadow-sm rounded-lg">
      <div>
        {/* formInfo->description */}
        <FormDescription data={formDescription} />

        <form onSubmit={handleSubmit} className="max-w-1xl mx-auto rounded space-y-2 px-8 mt-5">
          {/* formInputs->Description */}
          <div>
            <label
              htmlFor="Description"
              className="block text-gray-600 font-medium text-sm flex"
            >
              <Arsterisks />
              <span>Template reference & description</span>
            </label>
            <input
              type="text"
              id="Description"
              name="Description"
              value={formInputs.Description}
              onChange={(e) => handleInput("Description", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Description"
              required
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3">
            {/* rawData->department_arr : department  */}
            <div>
              <label
                htmlFor="DepartmentId"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks />
                <span>Department/Entity</span>
              </label>
              <Select
                value={formSelects.department}
                options={department}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("department", e)}
                // onChange={getApprovalSequence}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>

            {/* rawData->Asset */}
            <div>
              <label
                htmlFor="Asset"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> <span>Asset</span>
              </label>
              <Select
                value={formSelects.assets}
                options={assets}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("assets", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>

            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="LinkedToCategory"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Linked to Category
              </label>
              <Select
                value={formSelects.linked_to_category}
                options={linked_to_category}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("linked_to_category", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="ValidatedByCATMAN"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Validated by CATMAN
              </label>
              <Select
                value={formSelects.validated_by_catman}
                options={validated_by_catman}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("validated_by_catman", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="UpdatedByInsurance"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Template reviewed and updated by insurance
              </label>
              <Select
                value={formSelects.template_reviewed_and_updated_by_insurance}
                options={template_reviewed_and_updated_by_insurance}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("template_reviewed_and_updated_by_insurance", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="UpdatedByLegal"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Template reviewed and updated by Legal
              </label>
              <Select
                value={formSelects.template_reviewed_and_updated_by_legal}
                options={template_reviewed_and_updated_by_legal}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("template_reviewed_and_updated_by_legal", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="UpdatedByTax"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Template reviewed and updated by Tax
              </label>
              <Select
                value={formSelects.template_reviewed_and_updated_by_tax}
                options={template_reviewed_and_updated_by_tax}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("template_reviewed_and_updated_by_tax", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="UpdatedByFinance"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Template reviewed and updated by Cost Control/Finance
              </label>
              <Select
                value={formSelects.template_reviewed_and_updated_by_cost_control}
                options={template_reviewed_and_updated_by_cost_control}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("template_reviewed_and_updated_by_cost_control", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="UpdatedByCompliance"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Template reviewed and updated by Compliance
              </label>
              <Select
                value={formSelects.template_reviewed_and_updated_by_compliance}
                options={template_reviewed_and_updated_by_compliance}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("template_reviewed_and_updated_by_compliance", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>
            {/* rawData->MarketIntelligence */}
            <div>
              <label
                htmlFor="UpdatedByHSE"
                className="block text-gray-600 font-medium text-sm flex"
              >
                <Arsterisks /> Template reviewed and updated by HSE
              </label>
              <Select
                value={formSelects.template_reviewed_and_updated_by_hse}
                options={template_reviewed_and_updated_by_hse}
                isMulti={false}
                isSearchable={true}
                onChange={(e) => handleSelect("template_reviewed_and_updated_by_hse", e)}
                className="font-normal"
                placeholder="Select..."
                required
              />
            </div>

            {/* formInputs->NameOfBuyer */}
            <div>
              <label
                htmlFor="NameOfBuyer"
                className="block text-gray-600 font-medium text-sm flex"
              >
                Name of Buyer/CE
              </label>
              <input
                type="text"
                id="NameOfBuyer"
                name="NameOfBuyer"
                value={formInputs.NameOfBuyer}
                onChange={(e) => handleInput("NameOfBuyer", e)}
                className={form_input_style}
                placeholder="Name of Buyer/CE"
                disabled
              />
            </div>

            {/* AlternateEmailAddress */}
            <div>
              <label
                htmlFor="AlternateEmailAddress"
                className="block text-gray-600 font-medium text-sm flex"
              >
                Alternative Email Address
              </label>
              <Autocomplete
                className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                filterOptions={filterOptions}
                disableClearable={true}
                onChange={(e, v) => handleAlternateEmailAddress(e, v)}
                id="AlternateEmailAddress"
                options={tepng_users}
                value={formInputs.AlternateEmailAddress}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select..."
                    variant="standard"
                    {...params}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {getOptionLabel(option)}
                  </li>
                )}
              />
            </div>
          </div>

          {/* Justification */}
          <div>
            <label
              htmlFor="Justification"
              className="block text-gray-600 font-medium text-sm flex"
            >
              Justification
            </label>
            <textarea
              id="Justification"
              name="Justification"
              value={formInputs.Justification}
              onChange={(e) => handleInput("Justification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Enter Justification"
              style={{ height: "150px" }}
            ></textarea>
          </div>

          {/* Documents uploaded list */}
          <div>
            <button
              onClick={handleModal}
              type="button"
              className={`bg-gray-100 shadow-sm hover:bg-blue-100 font-[700] border border-gray-300 rounded-xs px-3 py-3 w-full text-sm`}>
              Attach File
            </button>

            {uploadedFiles.length > 0 && (
              <div className="container mx-auto mt-1">
                <div className="overflow-x-auto">
                  <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Sn
                        </th>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Attachment Category
                        </th>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Attached File
                        </th>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedFiles.map((item, i) => (
                        <tr
                          key={i}
                          className="transition-all hover:bg-gray-100 border"
                        >
                          <td className="px-3 py-2  text-xs">{i + 1}</td>
                          <td className="px-3 py-2  text-xs">
                            {uploadedFilesAttachType[i]}
                          </td>
                          <td className="px-3 py-2  text-xs">{item.name}</td>
                          <td className="px-3 py-2  text-xs">
                            <button
                              className="flex"
                              onClick={() => removeFile(i)}
                            >
                              <span style={{ marginTop: "2px" }}>
                                <XMark />
                              </span>
                              <span className="ml-1">Remove</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>

          {/* previously uploaded Documents uploaded list */}
          <div>
            {formDocuments.length > 0 && (
              <div className="container mx-auto mt-1">
                <p>Previously attched files</p>
                <div className="overflow-x-auto">
                  <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Sn
                        </th>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Attachment Category
                        </th>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Attached File
                        </th>
                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {formDocuments.map((item: any, i: any) => (
                        <tr
                          key={i}
                          className="transition-all hover:bg-gray-100 border"
                        >
                          <td className="px-3 py-2  text-xs">{i + 1}</td>
                          <td className="px-3 py-2  text-xs">
                            {item.attachmentType.name}
                          </td>
                          <td className="px-3 py-2  text-xs">{item.name}</td>
                          <td className="px-3 py-2  text-xs">
                            <button
                              className="flex"
                              onClick={() => removePrevFile(i)}
                            >
                              <span style={{ marginTop: "2px" }}>
                                <XMark />
                              </span>
                              <span className="ml-1">Remove</span>
                            </button>
                          </td>
                        </tr>
                      ))}

                      {uploadedFiles.length > 0 && uploadedFiles.map((item, i) => (
                        <tr key={i} className="transition-all hover:bg-gray-100 border">
                          <td className="px-3 py-2  text-xs">
                            {uploadedFilesAttachType[i]}
                          </td>
                          <td className="px-3 py-2  text-xs">{item.name}</td>
                          <td className="px-3 py-2  text-xs">
                            <button type="button" className="flex" onClick={() => removeFile(i)}>
                              <span style={{ marginTop: "2px" }}>
                                <XMark />
                              </span>
                              <span className="ml-1">Remove</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>

          {/* approval sequence list */}
          <FormApprovalOverlay data={form_approval} />

          {/* approval personnel */}
          {
            !!form_approval.length && (

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 pb-3">
                {form_approval.map((item: FormApproval, i) => {
                  return (
                    <div key={i}>
                      <label
                        htmlFor="user_label"
                        className="block text-gray-600 font-medium text-sm flex"
                      >
                        {item.roleName}
                      </label>
                      <Autocomplete
                        className="w-full border rounded-xs px-3 py- w-full text-sm"
                        filterOptions={filterOptions}
                        disableClearable={true}
                        onChange={(e, option) =>
                          handleFormApproval(e, option, item)
                        }
                        id="combo-box-demo"
                        options={tepng_users}
                        value={!!item.email ? item.email : ""}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select..."
                            variant="standard"
                            {...params}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{
                              fontFamily: "Nunito",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {getOptionLabel(option)}
                          </li>
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            )

          }

          {
            // approval-workflow-diagram
            !!form_approval.length && (
              <>
                <Label title="Approval Sequence" for="ApprovalSequence" />
                <ApprovalSequenceFlow data={form_approval} />
              </>
            )
          }


          <div className="mx-auto w-2/4">
            
          {(!formIDQuery || currentStatus === "DRAFT") && (
            <div className="flex pt-20 pb-1">
              <input
                type="checkbox"
                name="isChecked"
                id="validationCheck"
                onChange={(e) => setbuttonChecked(e.target.checked)}
                style={{ width: "20px" }}
              />
              <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                I validate that this form is ready to be routed{" "}
              </label>
            </div>
          )}

            {!formIDQuery ? (
              <div className="flex gap-4 justify-start">
                <button
                  type="submit"
                  disabled={!!isChecked}
                  className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                  name="save"
                  onClick={(e) => (clickedButtonRef.current = "save")}
                >
                  Save as Draft
                </button>
                <button
                  type="submit"
                  disabled={!isChecked}
                  className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                  name="post"
                  onClick={(e) => (clickedButtonRef.current = "post")}
                >
                  Submit Form
                </button>
              </div>
            ) : (
              <div className="flex gap-4 pt-3 justify-start">
                <button
                  type="button"
                  className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${currentStatus !== "RESTART" ? "" : "hidden"}`}
                  name="update"
                  disabled={!!isChecked}
                  // onClick={(e) => handleUpdate(e, "update_only")}
                  onClick={(e) => handleUpdate(e, currentStatus === "DRAFT" ? "update_only" : "update_and_submit")}
              
                >
                  {currentStatus === "DRAFT" ? 'Save As Draft' : 'Update Form'}
                </button>
                <button
                  type="button"
                  disabled={!isChecked}
                  className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${currentStatus === "DRAFT" ? "" : "hidden"}`}
                  name="post"
                  onClick={(e) => handleUpdate(e, "update_and_submit")}
                >
                  Submit Form
                </button>

                <button
                  type="button"
                  disabled={!!isChecked}
                  className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${currentStatus === "RESTART" ? "" : "hidden"}`}
                  name="post"
                  onClick={handleReview}
                >
                  Submit Restart
                </button>
              </div>
            )}
          </div>
        </form>

        {/* form-footer-note */}
        <FormNotes data={formDescription} />

        {/* Approval of Contracts Comment */}
        {/*<div className="bg-gray-50 mt-12 p-4 rounded-lg font-12 mt-5 rounded-tl-[0px] rounded-tr-[0] ">*/}
        {/*  <label className="block text-gray-700 font-bold flex">*/}
        {/*    <span className="pl-2 text-justify">*/}
        {/*      Approval of Contracts Comment*/}
        {/*    </span>*/}
        {/*  </label>*/}
        {/*  <div*/}
        {/*      className="text-justify text-sm form-description mt-2"*/}
        {/*      dangerouslySetInnerHTML={{__html: formInfo?.note}}*/}
        {/*  />*/}
        {/*</div>*/}

        {/* Uplooad modal */}
        {togggleModal && (
          <div
            className="fixed inset-0 flex items-center justify-center z-50"
            style={{ background: "rgb(0 0 0 / 52%)" }}
          >
            <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div className="flex justify-between">
                <div className="modal-content py-4 text-left px-6">
                  <h2 className="text-2xl font-bold">Attach File</h2>
                  <p className="text-xs">Kindly upload your attachment</p>
                </div>
                <div className="modal-content py-4 text-left px-6">
                  <button
                    onClick={handleModal}
                    className="p-2"
                    title="Close Modal"
                  >
                    <XMark />
                  </button>
                </div>
              </div>

              <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
                <div className="mb-3">
                  <label
                    htmlFor="AlternateEmailAddress"
                    className="block text-gray-700 font-bold"
                  >
                    Select Attachment to Upload
                  </label>
                  <Select
                    value={formSelects.selected_attachment_type}
                    options={attachment_type}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("selected_attachment_type", e)}
                    className="text-sm"
                    placeholder="Select..."
                  />
                  {formSelects.selected_attachment_type.label === "Others" && (
                    <input
                      type="text"
                      id="OthersDescription"
                      name="OthersDescription"
                      value={formInputs.OthersDescription}
                      onChange={(e) => handleInput("OthersDescription", e)}
                      className="border rounded-xs px-3 py-2 mt-2 w-full text-xs font-medium"
                      placeholder="Enter others description"
                      required
                    />
                  )}
                </div>

                <div>
                  <div style={{ display: !formSelects.selected_attachment_type.value ? "none" : formSelects.selected_attachment_type.label === "Others" && !formInputs.OthersDescription ? "none" : "block" }} {...getRootProps()} className="dropzoneStyle">
                    <input {...getInputProps()} />
                    <p>Drag & drop some files here, or click to select files</p>
                  </div>
                  {uploadedFiles.length > 0 && (
                    <div className="container mx-auto mt-3">
                      <div className="overflow-x-auto">
                        <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Sn
                              </th>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Attachment Category
                              </th>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Attached File
                              </th>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {uploadedFiles.map((item, i) => (
                              <tr
                                key={i}
                                className="transition-all hover:bg-gray-100 border"
                              >
                                <td className="px-3 py-2  text-xs">{i + 1}</td>
                                <td className="px-3 py-2  text-xs">
                                  {uploadedFilesAttachType[i]}
                                </td>
                                <td className="px-3 py-2  text-xs">
                                  {item.name}
                                </td>
                                <td className="px-3 py-2  text-xs">
                                  <button
                                    className="flex"
                                    onClick={() => removeFile(i)}
                                  >
                                    <span style={{ marginTop: "2px" }}>
                                      <XMark />
                                    </span>
                                    <span className="ml-1">Remove</span>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoutingSlipForDeviationStandardTemplate;
