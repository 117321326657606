import Select from "react-select";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import { XMark } from "../../../components/Icons";
import api from "../../../services/form.services";
import { formTypesID } from "../../../utils/data";
import { Label } from "../../../components/FormUI";
import FormNotes from "../../../components/FormNotes";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@mui/material/Autocomplete";
import { User } from "../../../interfaces/users.interface";
import FormDescription from "../../../components/FormDescription";
import CurrencyInput from "react-currency-input-field";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ApprovalSequenceFlow from "../../../components/ApprovalSequenceFlow";
import FormApprovalOverlay from "../../../components/FormApprovalOverlay";
import { useState, useEffect, useCallback, FormEvent, useRef } from "react";
import { form_input_style, getnamefromemail, tinyAlert } from "../../../utils";
import {
  OptionProps,
  FormWorkflow,
  FormApproval,
  FormDetails,
  RSMPInputs,
  RSMPSelects,
} from "../../../interfaces/forms.interface";
import rsmp from "../../../services/forms/RoutingSlipForMaterialPurchaseForm.services";
import form_util from "../../../services/FormUtil.services";

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => (
  <div
    dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : "" }}
  />
);

function RoutingSlipForMaterialPurchase() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  // global vars
  const user: User = useSelector((state: any) => state.tepngUser.value.user);
  const formIDQuery: any = params.id;
  const formName: string = location.pathname.split("/")[4];
  const formType: any = formTypesID.find((x: any) => x.name === formName)?.type;

  // const default state value;
  const defaultInputs: RSMPInputs = {
    BriefDescriptionOfMaterials: "",
    PRNumber: "",
    Supplier: "",
    Requisitioner: "",
    POAmount: "",
    PONumber: "",
    DeliveryPlace: "",
    POLocalContentValue: "",
    POLocalContentCalculationMethod: 0,
    POLocalContent: "",
    SavingsValueUSD: "",
    Addendum: "",
    BuyersComment: "",
    NameOfBuyer: user.firstName + " " + user.lastName,
    AlternateEmailAddress: "",
    OthersDescription: "",
    isDraft: false,
    SavingsAmount: "",
    ReferenceCost: "",
    NewCost: "",
    ContractJustification: "",
    SavingsJustification: "",
  };

  const defaultSelects: RSMPSelects = {
    po_currency: { label: "USD", value: 1, name: "currency" },
    department: { label: "Select...", value: null, name: "department" },
    ddq_status: { label: "Select...", value: null, name: "ddq_status" },
    material_type: { label: "Select...", value: null, name: "material_type" },
    nuprc_status: { label: "Select...", value: null, name: "nuprc_status" },
    procurement_method: {
      label: "Select...",
      value: null,
      name: "procurement_method",
    },
    saving_type: { label: "Select...", value: null, name: "saving_type" },
    incoterms: { label: "Select...", value: null, name: "incoterms" },
    human_right: { label: "Select...", value: null, name: "human_right" },
    less_three_bids: {
      label: "Select...",
      value: null,
      name: "less_three_bids",
    },
    VendorsAggregateCommitmentYTDGreaterThan1M: {
      label: "Select...",
      value: null,
      name: "VendorsAggregateCommitmentYTDGreaterThan1M",
    },
    selected_attachment_type: {
      label: "Select...",
      value: null,
      name: "selected_attachment_type",
    },
    date_of_prattribution: null,
    date_of_rfq: null,
    PlannedDelivery: null,
  };

  // page data default states.
  const [isChecked, setbuttonChecked] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<string>("DRAFT");
  const [parentFormId, setParentFormId] = useState<string>("");
  const [currencies, setCurrencies] = useState<OptionProps[]>([]);
  const [addendum1, setAddendum1] = useState<boolean>(false);
  const [addendum2, setAddendum2] = useState<boolean>(false);
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [ddq_status, setDDQStatus] = useState<OptionProps[]>([]);
  const [material_type, setMaterialTypes] = useState<OptionProps[]>([]);
  const [saving_type, setSavingTypes] = useState<OptionProps[]>([]);
  const [incoterms, setIncoterms] = useState<OptionProps[]>([]);
  const [nuprc_status, setDPRStatus] = useState<OptionProps[]>([]);

  const [human_right, setHumanRight] = useState<OptionProps[]>([]);

  const [vendors, setVendors] = useState<OptionProps[]>([]);
  const [procurement_method, setProcurementMethod] = useState<OptionProps[]>(
    []
  );
  const [yesno_notapplicable, setYesNoNotApplicable] = useState<OptionProps[]>(
    []
  );
  const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
  const [attachment_type, setAttachmentType] = useState<OptionProps[]>([]);
  const [formDescription, setFormDescription] = useState<FormDetails>();
  const [form_workflow, setFormWorkflow] = useState<FormWorkflow>();
  const [form_approval, setFormApproval] = useState<FormApproval[]>([]);
  const [formDocuments, setFormDocuments] = useState<any>([]);
  const VendorsAggregateCommitmentYTDGreaterThan1M: OptionProps[] = [
    { label: "Yes", value: "Yes", name: "less_three_bid_arr" },
    { label: "No", value: "No", name: "less_three_bid_arr" },
  ];
  const lessThreeBid: OptionProps[] = [
    { label: "Yes", value: "Yes", name: "less_three_bid_arr" },
    { label: "No", value: "No", name: "less_three_bid_arr" },
  ];
  const [removedDocumentIds, setRemovedDocumentIds] = useState<any>([]);
  const [formInputs, setFormInputs] = useState<RSMPInputs>(defaultInputs);
  const [formSelects, setFormSelects] = useState<RSMPSelects>(defaultSelects);
  const [togggleModal, setModalTogggle] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<
    string[]
  >([]);
  const clickedButtonRef = useRef("");

  console.log(yesno_notapplicable);
  // form-details
  useEffect(() => {
    getFormById(formIDQuery, user, navigate);
  }, [formIDQuery, user, navigate]);
  useEffect(() => {
    api.fetchFormDescription(formName).then((res) => setFormDescription(res));
  }, [formName]);

  // form-constants
  useEffect(() => {
    api.fetchDepartment().then((res) => setDepartment(res));
    api.fetchCurrency().then((res) => setCurrencies(res));
    api.fetchDdqStatus().then((res) => setDDQStatus(res));
    api.fetchMaterialTypes().then((res) => setMaterialTypes(res));
    api.fetchIncoterms().then((res) => setIncoterms(res));
    api.fetchSavingTypes().then((res) => setSavingTypes(res));
    api.fetchDPRStatus().then((res) => setDPRStatus(res));
    api.fetchProcurementMethod().then((res) => setProcurementMethod(res));
    api.fetchYesNoNotApplicable().then((res) => setYesNoNotApplicable(res));
    api.fetchTepngUsers().then((res) => setTepngUsers(res));
    api.fetchHumanRight().then((res) => setHumanRight(res));
    api.fetchVendors().then((res) => setVendors(res));
  }, []);

  useEffect(() => {
    api.fetchFormWorkFlow(formType).then((res) => setFormWorkflow(res));
    api
      .fetchFormAttachmentTypes(formType)
      .then((res) => setAttachmentType(res));
  }, [formType]);

  useEffect(() => {
    if (!!department.length) {
      let def_department: any = department.find(
        (a: any) => a.value === user.departmentId
      );
      if (!!def_department)
        formSelects.department = def_department;
    }

    
    // eslint-disable-next-line 
  }, [department]);

  // form-workflow-values
  useEffect(() => {
    const data = {
      ContractValue: formInputs.POAmount,
      currency: formSelects.po_currency,
      department: formSelects.department,
      form_workflow: form_workflow,
    };

    form_util.currencyThresholds(data);
  }, [
    formInputs.POAmount,
    formSelects.po_currency,
    formSelects.department,
    form_workflow,
  ]);

  // fetch-approval-sequence
  useEffect(() => {
    const data = {
      DepartmentId: formSelects.department.value,
      ContractValue: Number(formInputs.POAmount),
      Currency: formSelects.po_currency.value,
      LessThanThreeBidsInCFT:
        formSelects.less_three_bids.value === "Yes" ? true : false,
    };

    if (!!data.ContractValue && !!data.Currency && !!data.DepartmentId) {
      rsmp.fetchApprovalSequence(data).then((res) => {
        if (!!res?.length) {
          if (form_approval.length !== res.length) setFormApproval(res);
        }
      });
    }
  }, [
    formInputs.POAmount,
    formSelects.department,
    formSelects.po_currency,
    formSelects.less_three_bids,
    form_approval,
  ]);

  // form-hanlders
  const handleInput = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInputs({ ...formInputs, [key]: e.target.value });
  };
  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormSelects({ ...formSelects, [fieldName]: selectedOptions });
  };
  const handleDate = (date: any, input: string) => {
    setFormSelects({ ...formSelects, [input]: date });
  };
  // const handleCurrencyInput = (e: any) => {
  //     const { value, name } = e.target;
  //     let newValue = value.replace(/[^0-9]/g, '');
  //     setFormInputs({ ...formInputs, [name]: newValue });
  // };
  const handleFormApproval = (el: any, option: any, item: any) => {
    const new_form_approvals = form_approval.map((i: any) => {
      i.email = i.roleId === item.roleId ? option?.value : i.email;
      return i;
    });
    setFormApproval(new_form_approvals);
  };
  const handleAlternateEmailAddress = (el: any, option: any) => {
    setFormInputs({ ...formInputs, AlternateEmailAddress: option.value });
  };
  const handleRequisitioner = (el: any, option: any) => {
    setFormInputs({
      ...formInputs,
      Requisitioner: getnamefromemail(option.value),
    });
  };

  // file-local-upload
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newAttachTypeLabel =
        formSelects.selected_attachment_type.label === "Others"
          ? formInputs.OthersDescription
          : formSelects.selected_attachment_type.label;

      let uploadType = uploadedFilesAttachType;

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadType.push(newAttachTypeLabel);
      }

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...acceptedFiles,
      ]);
      setUploadedFilesAttachType(uploadType);
    },
    [
      formSelects.selected_attachment_type.label,
      formInputs.OthersDescription,
      uploadedFilesAttachType,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // remove attached file.
  const removeFile = (index: number) => {
    const updatedNames = [...uploadedFilesAttachType];
    updatedNames.splice(index, 1);

    const updated_files = [...uploadedFiles];
    updated_files.splice(index, 1);

    setUploadedFiles(updated_files);
    setUploadedFilesAttachType(updatedNames);
  };

  // remove attached file - when status is update form
  const removePrevFile = (index: number) => {
    const updated_files = [...formDocuments];
    const deletedFile = updated_files.splice(index, 1);
    setFormDocuments(updated_files);

    const removed_files = [...removedDocumentIds];
    removed_files.push(deletedFile[0].id);
    setRemovedDocumentIds(removed_files);
  };

  const handleCurrencyInput = (e: any) => {
    const { value, name } = e.target;
    
    let initial_value = value.replace(/[^0-9.]/g, "");
    let final_value = initial_value.replace(/^(\d*\.)(.*)\./g, "$1$2");

    setFormInputs({ ...formInputs, [name]: final_value });
  };

  // component-handlers
  const toggleUploadCardModal = () => {
    if (togggleModal) setModalTogggle(false);
    else setModalTogggle(true);
  };

  // [S1] rsmp-form-api-services-create-rsmp-form
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    let addendum = [];
    if (addendum1) {
      addendum.push("Addendum 1");
    }
    if (addendum2) {
      addendum.push("Addendum 2");
    }

    // if (addendum.length)
    //     setFormInputs({ ...formInputs, Addendum: addendum.join(",") });

    const data = {
      form_approval: form_approval,
      formInputs: {
        ...formInputs,
        POLocalContentValue: formInputs.POLocalContent
          ? (Number(formInputs.POLocalContent) / 100) *
            Number(formInputs.POAmount)
          : formInputs.POAmount,
        Addendum: addendum.join(","),
      },
      formSelects: { ...formSelects },
      IsDraft: clickedButtonRef?.current === "post" ? false : true,
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
    };

    rsmp.setForm(data).then((res: any) => {
      if (res?.id)
        navigate("/initiator/forms/history/routing-slip-for-material-purchase");
    });
  };

  const handleContractorsName = (el: any, option: any) => {
    setFormInputs({ ...formInputs, Supplier: option.value });
  };

  // rsmp-form-api-services-update-rsmp-form
  const handleUpdate = (e: FormEvent, action: string) => {
    e.preventDefault();

    let addendum = [];
    if (addendum1) {
      addendum.push("Addendum 1");
    }
    if (addendum2) {
      addendum.push("Addendum 2");
    }

    // if (addendum.length > 0){
    //     setFormInputs({ ...formInputs, Addendum: addendum.join(",") });

    // }
    // else{
    //     setFormInputs({ ...formInputs, Addendum: ''});

    // }

    const data = {
      form_approval: form_approval,
      formInputs: {
        ...formInputs,
        POLocalContentValue: formInputs.POLocalContent
          ? (Number(formInputs.POLocalContent) / 100) *
            Number(formInputs.POAmount)
          : formInputs.POAmount,
        Addendum: addendum.join(","),
      },
      formSelects: { ...formSelects },
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      removedDocumentIds: removedDocumentIds,
      formIDQuery: formIDQuery,
      IsDraft: action === "update_and_submit" ? false : true,
    };

    rsmp.updateForm(data).then((res) => {
      if (!!res?.id)
        navigate("/initiator/forms/history/routing-slip-for-material-purchase");
      // if (action === "update_and_submit") {
      //     rsmp.submitDraft(formIDQuery).then((res) => {
      //         if (!!res?.id) navigate("/forms/history/routing-slip-for-material-purchase");
      //     });
      // }
      // else{

      // }
    });
  };

  // rsmp-form-api-services-review-rsmp-form
  const handleReview = (e: FormEvent) => {
    e.preventDefault();

    let addendum = [];
    if (addendum1) {
      addendum.push("Addendum 1");
    }
    if (addendum2) {
      addendum.push("Addendum 2");
    }

    // if (addendum.length)
    //     setFormInputs({ ...formInputs, Addendum: addendum.join(",") });

    const data = {
      form_approval: form_approval,
      formInputs: {
        ...formInputs,
        POLocalContentValue: formInputs.POLocalContent
          ? (Number(formInputs.POLocalContent) / 100) *
            Number(formInputs.POAmount)
          : formInputs.POAmount,
        Addendum: addendum.join(","),
      },
      formSelects: { ...formSelects },
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      formIDQuery: formIDQuery,
      parentFormId: !!parentFormId ? parentFormId : formIDQuery,
    };

    rsmp.reviewForm(data).then((res: any) => {
      if (!!res?.id)
        navigate("/initiator/forms/history/routing-slip-for-material-purchase");
    });
  };

  // rsmp-form-api-services-get-rsmp-byid
  const getFormById = (formIDQuery: string, user: User, navigate: any) => {
    if (formIDQuery) {
      rsmp.fetchFormById(formIDQuery).then((res) => {
        if (!!res?.id) {
          let addendum = res?.addendum?.split(",");

          if (!!addendum?.length) {
            if (addendum.find((i: any) => i === "Addendum 1")) {
              setAddendum1(true);
            }
            if (addendum.find((i: any) => i === "Addendum 2")) {
              setAddendum2(true);
            }
          }

          setFormInputs({
            BriefDescriptionOfMaterials: res.briefDescriptionOfMaterials,
            PRNumber: res.prNumber,
            Supplier: res.supplier,
            Requisitioner: res.requisitioner,
            POAmount: res.poAmount,
            PONumber: res.poNumber,
            DeliveryPlace: res.deliveryPlace,
            AlternateEmailAddress: res.alternateEmailAddress,
            OthersDescription: res.othersDescription,
            POLocalContentValue: res.poLocalContentValue,
            POLocalContentCalculationMethod:
              res.poLocalContentCalculationMethod,
            POLocalContent: res.poLocalContent,
            SavingsValueUSD: res.savingsValueUSD,
            Addendum: res.addendum,
            NameOfBuyer: user.firstName + " " + user.lastName,
            BuyersComment: res.buyersComment,
            isDraft: res.isDraft,
            SavingsAmount: res.savingsAmount,
            ReferenceCost: res.referenceCost,
            NewCost: res.newCost,
            ContractJustification: res.contractJustification,
            SavingsJustification: res.savingsJustification,
          });
          setFormSelects({
            po_currency: {
              label: res.currencyDetails.value,
              value: res.currencyDetails.id,
              name: "currency",
            },
            department: {
              label: res.department.name,
              value: res.department.id,
              name: "department",
            },
            ddq_status: {
              label: res.ddqStatusDetails.name,
              value: res.ddqStatusDetails.id,
              name: "ddq_status",
            },
            human_right: {
              label: res.humanRightsDetails?.name,
              value: res.humanRightsDetails?.id,
              name: "human_right",
            },
            material_type: {
              label: res.materialTypeDetails.name,
              value: res.materialTypeDetails.id,
              name: "material_type",
            },
            nuprc_status: {
              label: res.nuprcStatusDetails.name,
              value: res.nuprcStatusDetails.id,
              name: "nuprc_status",
            },
            procurement_method: {
              label: res.procurementMethodDetails.name,
              value: res.procurementMethodDetails.id,
              name: "procurement_method",
            },
            saving_type: {
              label: res.savingTypeDetails.name,
              value: res.savingTypeDetails.id,
              name: "saving_type",
            },
            incoterms: {
              label: res.incotermsDetails.name,
              value: res.incotermsDetails.id,
              name: "incoterms",
            },
            less_three_bids: {
              label: res.lessThanThreeBidsInCFT ? "Yes" : "No",
              value: res.lessThanThreeBidsInCFT ? "Yes" : "No",
              name: "less_three_bids",
            },
            VendorsAggregateCommitmentYTDGreaterThan1M: {
              label: res.vendorsAggregateCommitmentYTDGreaterThan1M
                ? "Yes"
                : "No",
              value: res.vendorsAggregateCommitmentYTDGreaterThan1M
                ? "Yes"
                : "No",
              name: "VendorsAggregateCommitmentYTDGreaterThan1M",
            },
            selected_attachment_type: {
              label: "Select...",
              value: "",
              name: "selected_attachment_type",
            },
            date_of_prattribution: new Date(res.dateOfPRAttribution),
            date_of_rfq: new Date(res.dateOfRFQ),
            PlannedDelivery: new Date(res.plannedDelivery),
          });

          const approval_sort = res.formApprovers.sort(
            (a: any, b: any) => a.step - b.step
          );
          const approvals: any[] = approval_sort.map((i: any) => ({
            email: i.email,
            roleId: i.roleId,
            roleName: i.role.name,
          }));

          // let currentStatus = ''
          // for(let i = 0;  i < approval_sort.length; i++){
          //     if(approval_sort[i].formApproval){
          //         currentStatus = approval_sort[i]?.formApproval?.status
          //     }
          // }

          // if(currentStatus === 'APPROVED'){
          //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
          //     navigate("/forms/history/cpnc-routing-slip");
          //     return;
          // }

          // if(res.currentStatus !== 'RESTART' && res.currentStatus !== 'DRAFT'){
          //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
          //     navigate("/forms/history/cpnc-routing-slip");
          //     return;
          // }
          if (res.canEditOrDelete !== true && res.currentStatus !== "RESTART") {
            tinyAlert(
              "error",
              "Form cannot be edited because the first level approver has acted on it"
            );
            navigate("/initiator/forms/history/cpnc-routing-slip");
            return;
          }

          setFormApproval(approvals);
          setFormDocuments(res.documents);
          setCurrentStatus(res.currentStatus);
          setParentFormId(res.parentFormId);
        }
      });
    }
  };

  // ui-component
  return (
    <div className="bg-white w-full rounded-lg mb-12 shadow">
      <FormDescription data={formDescription} />

      <form
        onSubmit={handleSubmit}
        className="max-w-1xl mx-auto rounded space-y-2 px-8 mt-5"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3">
          <div>
            <Label
              title="Brief Description of Material(s)"
              for="BriefDescriptionOfMaterials"
              required
            />
            <input
              id="BriefDescriptionOfMaterials"
              type="text"
              value={formInputs.BriefDescriptionOfMaterials}
              onChange={(e) => handleInput("BriefDescriptionOfMaterials", e)}
              className={form_input_style}
              placeholder="Brief Description of Material(s)"
              required
            />
          </div>

          <div>
            <Label title="Supplier" required />
            <Autocomplete
              className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
              filterOptions={filterOptions}
              disableClearable={true}
              onChange={(e, v) => handleContractorsName(e, v)}
              id="AlternateEmailAddress"
              options={vendors}
              value={formInputs?.Supplier}
              renderInput={(params) => (
                <TextField
                  placeholder="Select..."
                  variant="standard"
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {getOptionLabel(option)}
                </li>
              )}
            />
          </div>

          {/* 
          <div className="col-span-full">
            <Label title="Supplier" for="Supplier" required />
            <input
              id="Supplier"
              type="text"
              value={formInputs.Supplier}
              onChange={(e) => handleInput("Supplier", e)}
              className={form_input_style}
              placeholder="Supplier"
              required
            />
          </div> */}

          <div>
            <Label title="PR/RFQ Number" for="PRNumber" required />
            <input
              id="PRNumber"
              type="text"
              value={formInputs.PRNumber}
              onChange={(e) => handleInput("PRNumber", e)}
              className={form_input_style}
              placeholder="PR/RFQ Number"
              required
              maxLength={10}
              minLength={8}
            />
          </div>

          <div>
            <Label title="Requisitioner/User" for="Requisitioner" required />
            <Autocomplete
              className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
              filterOptions={filterOptions}
              disableClearable={true}
              onChange={(e, v) => handleRequisitioner(e, v)}
              id="Requisitioner"
              options={tepng_users}
              value={formInputs.Requisitioner}
              renderInput={(params) => (
                <TextField
                  placeholder="Select..."
                  variant="standard"
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {getOptionLabel(option)}
                </li>
              )}
            />
          </div>

          <div>
            <Label title="Currency" for="po_currency" required />
            <Select
              id="po_currency"
              value={formSelects.po_currency}
              options={currencies}
              isMulti={false}
              isSearchable={false}
              onChange={(e) => handleSelect("po_currency", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="PO Amount" for="POAmount" required />
            {/* <input
              id="POAmount"
              type="text"
              value={formInputs.POAmount}
              onChange={(e) => handleInput("POAmount", e)}
              className={form_input_style}
              placeholder="PO Amount"
              required
            /> */}

            <CurrencyInput
              id="POAmount"
              name="POAmount"
              value={
                formInputs.POAmount
              }
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="PO Amount"
              required
            />
          </div>

          <div>
            <Label title="PO/OA Number" for="PONumber" required />
            <input
              id="PONumber"
              type="text"
              value={formInputs.PONumber}
              onChange={(e) => handleInput("PONumber", e)}
              className={form_input_style}
              placeholder="PO/OA Number"
              required
              maxLength={10}
              minLength={10}
            />
          </div>

          <div>
            <Label
              title="Vendor's aggregate commitment YTD>$1m"
              for="VendorsAggregateCommitmentYTDGreaterThan1M"
              required
            />
            <Select
              id="VendorsAggregateCommitmentYTDGreaterThan1M"
              value={formSelects.VendorsAggregateCommitmentYTDGreaterThan1M}
              options={VendorsAggregateCommitmentYTDGreaterThan1M}
              isMulti={false}
              isSearchable={false}
              onChange={(e) =>
                handleSelect("VendorsAggregateCommitmentYTDGreaterThan1M", e)
              }
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Date of PR Attribution" required />
            <DatePicker
              id="date_of_prattribution"
              className="border rounded px-3 py-2 w-full font-normal input"
              selected={formSelects.date_of_prattribution}
              onChange={(date) => handleDate(date, "date_of_prattribution")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              required={true}
            />
          </div>

          <div>
            <Label title="Date of RFQ" required />
            <DatePicker
              id="date_of_rfq"
              className="border rounded px-3 py-2 w-full font-normal input"
              selected={formSelects.date_of_rfq}
              onChange={(date) => handleDate(date, "date_of_rfq")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              required
            />
          </div>

          <div>
            <Label
              title={`Human Rights`}
              info={
                "Not Applicable can be selected by CE /Buyer if the contract is below CPSC threshold, no report on HR Audit from Ethics and there is no pending case on HR for the Contractor"
              }
              required
            />

            <Select
              id="human_right"
              value={formSelects.human_right}
              options={human_right}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("human_right", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Department/Entity" for="department" required />
            <Select
              id="department"
              value={formSelects.department}
              options={department}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("department", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Material Type" required />
            <Select
              id="material_type"
              value={formSelects.material_type}
              options={material_type}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("material_type", e)}
              className="font-normal"
              placeholder="Select..."
            />
          </div>

          <div>
            <Label title="DDQ Status/PRA Check" required />
            <Select
              id="ddq_status"
              value={formSelects.ddq_status}
              options={ddq_status}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("ddq_status", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="DPR Permit Status" required />
            <Select
              id="ddq_status"
              value={formSelects.nuprc_status}
              options={nuprc_status}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("nuprc_status", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Name of Buyer" />
            <input
              id="NameOfBuyer"
              type="text"
              value={formInputs.NameOfBuyer}
              onChange={(e) => handleInput("NameOfBuyer", e)}
              className={form_input_style}
              placeholder="Name of Buyer"
              disabled
            />
          </div>

          <div>
            <Label title="Alternative Email Address" />
            <Autocomplete
              className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
              filterOptions={filterOptions}
              disableClearable={true}
              onChange={(e, v) => handleAlternateEmailAddress(e, v)}
              id="AlternateEmailAddress"
              options={tepng_users}
              value={formInputs.AlternateEmailAddress}
              renderInput={(params) => (
                <TextField
                  placeholder="Select..."
                  variant="standard"
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {getOptionLabel(option)}
                </li>
              )}
            />
          </div>

          <div>
            <Label title="New Cost" for="NewCost" required />
            <CurrencyInput
              id="NewCost"
              name="NewCost"
              value={formInputs.NewCost}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="New Cost"
              required
            />
          </div>
          <div>
            <Label title="Reference Cost" for="ReferenceCost" required />
            <CurrencyInput
              id="ReferenceCost"
              name="ReferenceCost"
              value={formInputs.ReferenceCost}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Reference Cost"
              required
            />
          </div>

          <div>
            <Label title="Savings Amount" for="SavingsAmount" required />
            <CurrencyInput
              id="SavingsAmount"
              name="SavingsAmount"
              value={
                Number(formInputs?.NewCost) - Number(formInputs?.ReferenceCost)
              }
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Savings Amount"
              disabled
              required
            />
          </div>
          <div>
            <Label title="Contract Justification" required />
            <textarea
              id="ContractJustification"
              value={formInputs.ContractJustification}
              onChange={(e) => handleInput("ContractJustification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Contract Justification"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div>

          <div>
            <Label
              title="Savings Justification / Calculation Method"
              required
            />
            <textarea
              id="SavingsJustification"
              value={formInputs.SavingsJustification}
              onChange={(e) => handleInput("SavingsJustification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Savings Justification"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div>

          <div className="col-span-full">
            <Label title="Buyer's Comment" for="BuyersComment" required />
            <textarea
              id="BuyersComment"
              value={formInputs.BuyersComment}
              onChange={(e) => handleInput("BuyersComment", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Buyer's Comment"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div>

          <div className="col-span-full bg-gray-100 border-t mt-5 mb-5 p-5 rounded-lg font-12 rounded-tl-[0px] rounded-tr-[0] shadow-lg">
            PO Other Data (Complete as appropriate)
            <Label
              className="font-[300]"
              title="Local Content Calculation Method(%)"
            />
          </div>

          {/* <div>
            <input
              id="POLocalContentCalculationMethod"
              type="text"
              value={`${formInputs.POLocalContentCalculationMethod}`}
              onChange={(e) =>
                handleInput("POLocalContentCalculationMethod", e)
              }
              className={form_input_style}
              placeholder="Local Content Calculation Method(%)"
              disabled
            />
          </div> */}

          <div>
            <Label title="Procurement Method" required />
            <Select
              id="procurement_method"
              value={formSelects.procurement_method}
              options={procurement_method}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("procurement_method", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Local Content Value" />
            <input
              id="POLocalContentCalculationMethod"
              type="text"
              value={
                formInputs.POLocalContent
                  ? (Number(formInputs.POLocalContent) / 100) *
                    Number(formInputs.POAmount)
                  : formInputs.POAmount
              }
              onChange={(e) => handleInput("POLocalContentValue", e)}
              className={form_input_style}
              placeholder="Local Content Value"
              disabled
            />
          </div>

          <div>
            <Label title="Incoterms" required />
            <Select
              id="incoterms"
              value={formSelects.incoterms}
              options={incoterms}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("incoterms", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="PO Local Content(%)" required />
            <input
              id="POLocalContent"
              type="number"
              value={formInputs.POLocalContent}
              onChange={(e) => handleInput("POLocalContent", e)}
              className={form_input_style}
              placeholder="PO Local Content(%)"
              required
            />
          </div>

          <div>
            <Label title="Planned Delivery" required />
            <DatePicker
              id="PlannedDelivery"
              className="border rounded px-3 py-2 w-full font-normal input"
              selected={formSelects.PlannedDelivery}
              onChange={(date) => handleDate(date, "PlannedDelivery")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              required={true}
            />
          </div>

          <div>
            <Label title="Saving Type" required />
            <Select
              id="saving_type"
              value={formSelects.saving_type}
              options={saving_type}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("saving_type", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Delivery Place" required />
            <input
              id="DeliveryPlace"
              type="text"
              value={formInputs.DeliveryPlace}
              onChange={(e) => handleInput("DeliveryPlace", e)}
              className={form_input_style}
              placeholder="Delivery Place"
              required
            />
          </div>

          <div className="hidden">
            <Label title="Savings Value(USD)" />
            <input
              id="SavingsValueUSD"
              type="text"
              value={formInputs.SavingsValueUSD}
              onChange={(e) => handleInput("SavingsValueUSD", e)}
              className={form_input_style}
              placeholder="Savings Value(USD)"
            />
          </div>

          <div>
            <Label title="Addendum?" />
            <div className="flex border border-gray-300 pt-1 pb-1 px-2 rounded-xs input">
              <div className="flex mr-5 pb-">
                <input
                  type="checkbox"
                  name="isChecked"
                  id="validationCheck"
                  onChange={(e) => setAddendum1(e.target.checked)}
                  checked={addendum1}
                  style={{ width: "20px" }}
                />
                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                  Addendum 1
                </label>
              </div>
              <div className="flex pb-1">
                <input
                  type="checkbox"
                  name="isChecked"
                  id="validationCheck"
                  onChange={(e) => setAddendum2(e.target.checked)}
                  checked={addendum2}
                  style={{ width: "20px" }}
                />
                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                  Addendum 2
                </label>
              </div>
            </div>
          </div>

          <div>
            <Label
              title="Less than 3 bids / deviation from C&P Procedure"
              required
            />
            <Select
              id="less_three_bids"
              value={formSelects.less_three_bids}
              options={lessThreeBid}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("less_three_bids", e)}
              className="font-normal"
              placeholder="Select..."
            />
          </div>

          <div className="mt-5">
            <button
              onClick={toggleUploadCardModal}
              type="button"
              className={`bg-gray-50 hover:bg-blue-50 font-[700] border border-gray-300 rounded-xs px-3 py-2 w-full text-sm`}
            >
              Upload Attachment
            </button>
          </div>
        </div>

        {/* attach-document-area */}
        <div
          className={`container mx-auto ${
            !!formDocuments.length || !!uploadedFiles.length ? "" : "hidden"
          }`}
        >
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Attachment Category
                  </th>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Attached File
                  </th>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {formDocuments.length > 0 &&
                  formDocuments.map((item: any, i: any) => (
                    <tr
                      key={i}
                      className="transition-all hover:bg-gray-100 border"
                    >
                      <td className="px-3 py-2  text-xs">
                        {item.attachmentType.name}
                      </td>
                      <td className="px-3 py-2  text-xs">{item.name}</td>
                      <td className="px-3 py-2  text-xs">
                        <button
                          type="button"
                          className="flex"
                          onClick={() => removePrevFile(i)}
                        >
                          <span style={{ marginTop: "2px" }}>
                            <XMark />
                          </span>
                          <span className="ml-1">Remove</span>
                        </button>
                      </td>
                    </tr>
                  ))}

                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((item, i) => (
                    <tr
                      key={i}
                      className="transition-all hover:bg-gray-100 border"
                    >
                      <td className="px-3 py-2  text-xs">
                        {uploadedFilesAttachType[i]}
                      </td>
                      <td className="px-3 py-2  text-xs">{item.name}</td>
                      <td className="px-3 py-2  text-xs">
                        <button
                          type="button"
                          className="flex"
                          onClick={() => removeFile(i)}
                        >
                          <span style={{ marginTop: "2px" }}>
                            <XMark />
                          </span>
                          <span className="ml-1">Remove</span>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* approval personnel */}
        <FormApprovalOverlay data={form_approval} />

        {!!form_approval.length && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 pb-3">
            {form_approval?.map((item: FormApproval, i) => {
              return (
                <div key={i}>
                  <label className="block text-gray-600 font-medium text-sm flex">
                    {item.roleName}
                  </label>
                  <Autocomplete
                    className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                    filterOptions={filterOptions}
                    disableClearable={true}
                    onChange={(e, option) =>
                      handleFormApproval(e, option, item)
                    }
                    id="combo-box-demo"
                    options={tepng_users}
                    value={!!item.email ? item.email : ""}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select..."
                        variant="standard"
                        {...params}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          fontFamily: "Nunito",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {getOptionLabel(option)}
                      </li>
                    )}
                  />
                </div>
              );
            })}
          </div>
        )}

        {
          // approval-workflow-diagram
          !!form_approval.length && (
            <>
              <Label title="Approval Sequence" for="ApprovalSequence" />
              <ApprovalSequenceFlow data={form_approval} />
            </>
          )
        }

        <div className="mx-auto w-2/4">
          {(!formIDQuery || currentStatus === "DRAFT") && (
            <div className="flex pt-12 pb-3">
              <input
                type="checkbox"
                name="isChecked"
                id="validationCheckQ"
                onChange={(e) => setbuttonChecked(e.target.checked)}
                style={{ width: "20px" }}
              />
              <label className="ml-3 mt-1 text-sm" htmlFor="validationCheckQ">
                I validate that this form is ready to be routed{" "}
              </label>
            </div>
          )}

          {!formIDQuery ? (
            <div className="flex gap-4 justify-start">
              <button
                type="submit"
                disabled={!!isChecked}
                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                name="save"
                onClick={(e) => (clickedButtonRef.current = "save")}
              >
                Save as Draft
              </button>

              <button
                type="submit"
                disabled={!isChecked}
                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                name="post"
                onClick={(e) => (clickedButtonRef.current = "post")}
              >
                Submit Form
              </button>
            </div>
          ) : (
            <div className="flex gap-4 pt-3 justify-start">
              <button
                type="button"
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm ${
                  currentStatus !== "RESTART" ? "w-full" : "hidden"
                }`}
                name="update"
                disabled={!!isChecked}
                // onClick={(e) => handleUpdate(e, "update_only")}

                onClick={(e) =>
                  handleUpdate(
                    e,
                    currentStatus === "DRAFT"
                      ? "update_only"
                      : "update_and_submit"
                  )
                }
              >
                {currentStatus === "DRAFT" ? "Save As Draft" : "Update Form"}
              </button>

              <button
                type="button"
                disabled={!isChecked}
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${
                  currentStatus === "DRAFT" ? "w-full" : "hidden"
                }`}
                name="post"
                onClick={(e) => handleUpdate(e, "update_and_submit")}
              >
                Submit Form
              </button>

              <button
                type="button"
                disabled={!!isChecked}
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${
                  currentStatus === "RESTART" ? "" : "hidden"
                }`}
                name="post"
                onClick={handleReview}
              >
                Submit Restart
              </button>
            </div>
          )}
        </div>
      </form>

      {/* form-footer-note */}
      <FormNotes data={formDescription} />

      {/* Uplooad modal */}
      {togggleModal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ background: "rgb(0 0 0 / 52%)" }}
        >
          <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="flex justify-between">
              <div className="modal-content py-4 text-left px-6">
                <h2 className="text-2xl font-bold">Attach File</h2>
                <p className="text-xs">Kindly upload your attachment</p>
              </div>
              <div className="modal-content py-4 text-left px-6">
                <button
                  onClick={toggleUploadCardModal}
                  className="p-2"
                  title="Close Modal"
                >
                  <XMark />
                </button>
              </div>
            </div>

            <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
              <div className="mb-3">
                <label className="block text-gray-700 font-bold">
                  Select Attachment to Upload
                </label>
                <Select
                  value={formSelects.selected_attachment_type}
                  options={attachment_type}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleSelect("selected_attachment_type", e)}
                  className="text-sm"
                  placeholder="Select..."
                />
                {formSelects.selected_attachment_type.label === "Others" && (
                  <input
                    id="OthersDescription"
                    type="text"
                    value={formInputs.OthersDescription}
                    onChange={(e) => handleInput("OthersDescription", e)}
                    className={form_input_style}
                    placeholder="Enter description"
                    required
                  />
                )}
              </div>

              <div>
                <div
                  style={{
                    display: !formSelects.selected_attachment_type.value
                      ? "none"
                      : formSelects.selected_attachment_type.label ===
                          "Others" && !formInputs.OthersDescription
                      ? "none"
                      : "block",
                  }}
                  {...getRootProps()}
                  className="dropzoneStyle"
                >
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                {uploadedFiles.length > 0 && (
                  <div className="container mx-auto mt-3">
                    <div className="overflow-x-auto">
                      <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Sn
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Attachment Category
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Attached File
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedFiles.map((item, i) => (
                            <tr
                              key={i}
                              className="transition-all hover:bg-gray-100 border"
                            >
                              <td className="px-3 py-2  text-xs">{i + 1}</td>
                              <td className="px-3 py-2  text-xs">
                                {uploadedFilesAttachType[i]}
                              </td>
                              <td className="px-3 py-2  text-xs">
                                {item.name}
                              </td>
                              <td className="px-3 py-2  text-xs">
                                <button
                                  className="flex"
                                  onClick={() => removeFile(i)}
                                >
                                  <span style={{ marginTop: "2px" }}>
                                    <XMark />
                                  </span>
                                  <span className="ml-1">Remove</span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoutingSlipForMaterialPurchase;

/**
 * path: /forms/create/admin-amendment-form
 * path: /forms/edit/admin-amendment-form
 * note: component create & edit
 * last-refactored: yes - 14/02/2024
 */
