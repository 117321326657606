import React from 'react';
// import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { _$http, axiosError, closeAlert, preloader, formatDateTime} from '../../utils';
import { Edit, Trash, XMark } from '../../components/Icons';
import { tinyAlert } from '../../services/_config.services';


function Departments() {
  const [toggleCreateModal, setToggleCreateModal] = useState(false)
  const [toggleEditModal, setToggleEditModal] = useState(false)
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [formData, setFormData] = useState([])

  useEffect(() => {
    // setCurrentURL(window.location.href)
    getDepartments()
  }, []);

  
  const getDepartments = () => {
    preloader();  
    _$http('GET', 'Departments').then(res => {
        closeAlert();
        const record = res.data.data
        setFormData(record)
    })
    .catch(error => {
        axiosError(error);
    })
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    code: ""
  })
  const [deptId, setDeptId] = useState(null)

  const handleInputChange = (e:any) => {
    const {name, value} = e.target
    setFormInputs({...formInputs, [name]: value})
  }

  const handleEditFields = (name:any, id:any, code:any) => {
    setFormInputs({...formInputs, name: name, code: code})
    setDeptId(id)
  }

  const handleSubmit = (e:any) => {
    e.preventDefault()
    preloader(); 
    _$http('POST', 'Departments', formInputs).then(res => {
        closeAlert();
        setToggleCreateModal(false)
        tinyAlert('success', res.data.message) 
        getDepartments()
    })
    .catch(error => {
        axiosError(error);
    })
  };

  const handleUpdate = (e:any) => {
    e.preventDefault()
    
    preloader(); 
    _$http('PUT', 'Departments/'+deptId, formInputs).then(res => {
        closeAlert();
        setToggleEditModal(false)
        tinyAlert('success', res.data.message) 
        setDeptId(null)   
        getDepartments()
    })
    .catch(error => {
        axiosError(error);
    })
  };

  const handleDelete = () => {
    preloader(); 
    setToggleConfirmationModal(false)
    _$http('DELETE', 'Departments/'+deptId).then(res => {
        closeAlert();     
        setDeptId(null)   
        const message = res.data.message
        console.log(message)   
        tinyAlert('success', message) 
        getDepartments()
    })
    .catch(error => {
        axiosError(error);
    })
  };
  

  return (
      <div className='bg-white w-full shadow-sm rounded-lg py-6'>
        <div className='flex justify-end px-6'>
           <button onClick={() => setToggleCreateModal(true)} className='red-button rounded px-4 py-2 text-sm'>Create Department</button>
        </div>
        
        <div className="relative overflow-x-auto py-4" style={{minHeight: "600px"}}>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 notdark:text-gray-400">
                <thead className=" text-[14px] text-gray-700 uppercase bg-gray-50 notdark:bg-gray-700 notdark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            SN
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Department
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Short Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Created Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Updated Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                      formData.map((item:any, index:number) => {
                        return (
                          <tr className="bg-white border-b notdark:bg-gray-800 notdark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                                {index + 1}
                            </th>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                                {item.name}
                            </th>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                                {item.code}
                            </th>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                                {formatDateTime(item.createdDate)}
                            </th>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap notdark:text-white">
                                {formatDateTime(item.updatedDate)}
                            </th>

                            <td className="px-6 py-4">      
                              <div className='text-blue-600 flex gap-6'>
                                  <button onClick={() => {setToggleEditModal(true); handleEditFields(item.name, item.id, item.code)}} className='flex gap-2'>
                                    <Edit />
                                    <span className='py-1'>Edit</span>
                                  </button>
                                  <button onClick={() => {setToggleConfirmationModal(true); setDeptId(item.id)}} className='flex gap-2 text-red-600'>
                                    <Trash />
                                    <span className='py-1'>Delete</span>
                                  </button>
                              </div>
                            </td>
                        </tr>
                        )
                      })
                    }
                </tbody>
            </table>
        </div>

        {
          toggleCreateModal &&
          <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
              <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
                  <div className='flex justify-between'>
                      <h2 className="text-lg">Create Department</h2>
                      <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="modal-content my-4">
                        <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Department</label>
                        <input onChange={handleInputChange} type="text" name='name' required style={{minWidth:"300px"}} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" />                          
                    </div>
                    <div className="modal-content my-4">
                        <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Short Name</label>
                        <input onChange={handleInputChange} type="text" name='code' required style={{minWidth:"300px"}} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" />                          
                    </div>
                    
                    <div className='modal-footer flex justify-end'>
                    <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 notdark:bg-blue-600 notdark:hover:bg-blue-700 focus:outline-none notdark:focus:ring-blue-800">Submit</button>
                    </div>
                  </form>
              </div>
          </div>
        } 
        {
          toggleEditModal &&
          <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
              <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
                  <div className='flex justify-between'>
                      <h2 className="text-lg">Edit Department</h2>
                      <button onClick={() => setToggleEditModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
                  </div>

                  <form onSubmit={handleUpdate}>
                    <div className="modal-content my-4">
                        <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Department</label>
                        <input onChange={handleInputChange} value={formInputs.name} type="text" name='name' required style={{minWidth:"300px"}} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" />                          
                    </div>
                    <div className="modal-content my-4">
                        <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 notdark:text-white">Short Name</label>
                        <input onChange={handleInputChange} value={formInputs.code} type="text" name='code' required style={{minWidth:"300px"}} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 notdark:bg-gray-700 notdark:border-gray-600 notdark:placeholder-gray-400 notdark:text-white notdark:focus:ring-blue-500 notdark:focus:border-blue-500" />                          
                    </div>
                    
                    <div className='modal-footer flex justify-end'>
                    <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 notdark:bg-blue-600 notdark:hover:bg-blue-700 focus:outline-none notdark:focus:ring-blue-800">Update</button>
                    </div>
                  </form>
              </div>
          </div>
        } 
        {
          toggleConfirmationModal &&
          <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
              <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
                  <div className='flex justify-between'>
                      <h2 className="text-md">Are you sure you want to delete this department?</h2>
                      <button onClick={() => setToggleConfirmationModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
                  </div>

                  <div className="modal-content text-left px-6 flex justify-center gap-2 mt-4">
                      <button onClick={() => setToggleConfirmationModal(false)} type="button" className="rounded text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 notdark:bg-gray-800 notdark:hover:bg-gray-700 notdark:focus:ring-gray-700 notdark:border-gray-700">Cancel</button>
                      <button onClick={() => handleDelete()} type="button" className="rounded text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 notdark:bg-blue-600 notdark:hover:bg-blue-700 focus:outline-none notdark:focus:ring-blue-800">Yes</button>
                  </div>
              </div>
          </div>
        }   
      </div>
  );
}

export default Departments;
