import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { axiosError, closeAlert, confirmAlert, preloader, tinyAlert, _$http } from '../utils';
import api from '../services/form.services';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { OptionProps } from 'react-select';

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => (<div dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : '' }} />);

function FormApproval(props: any) {
    const navigate = useNavigate();
    const [comment, setComment] = useState('');
    const [commentFiles, selectedFiles] = useState<any[]>([]);
    // const [extendWorkflow, setextendWorkflow] = useState<boolean>(false);
    const [toRoleId, settoRoleId] = useState<string>('');
    const [toEmail, settoEmail] = useState<string>('');
    const [FormApprovers, setFormApprovers] = useState<any[]>([]);
    const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
    const [allROles, setallROles] = useState<any[]>([]);
    // const [approvals, setApprovals] = useState<any[]>(props.FormApprovers);

    //extendWorkflow:true
    //toRoleId:MD
    //toEmail:email

    const storeFile = (e: any) => {
        selectedFiles(e.target.files);
    }

    // useEffect(() => {setApprovals(props.FormApprovers)}, [props.FormApprovers]);
    useEffect(() => {canExtendForm(props.FormApprovers, props.formApproverId)}, [props.FormApprovers, props.formApproverId]);
    useEffect(() => {getRoles(); api.fetchTepngUsers().then((res) => setTepngUsers(res)); }, []);

    const getRoles = () => {
        preloader();
        _$http('GET', 'Roles').then(res => {
            closeAlert();
            setallROles(res.data.data);
        })
            .catch(error => {
                axiosError(error);
            })
    }

    const approveForm = () => {
        if(toEmail !== '' && FormApprovers.length === 0){
            tinyAlert('error', 'Kindly add the approvals to continue');
            return;
        }



        confirmAlert(`Do you want to approve form?`).then((r: any) => {
            if (r.isConfirmed) {
                let extendWorkflow = false;
                if (!!FormApprovers.length)
                    extendWorkflow = true;

                api.approvalWorkflow(props.formApproverId, props.formName, 6, comment, commentFiles, extendWorkflow, toRoleId, toEmail, FormApprovers)
                    .then(res => {
                        if (res) navigate('/approval/forms/pending/' + props.redirectURL);
                    })
            }
        })
    };
    const rejectForm = () => {
        if (!comment) {
            tinyAlert('error', 'Kindly enter a comment!');
            return;
        }

        confirmAlert(`Do you want to reject form?`).then((r: any) => {
            if (r.isConfirmed) {
                api.approvalWorkflow(props.formApproverId, props.formName, 7, comment, commentFiles, false, toRoleId, toEmail, FormApprovers)
                    .then(res => {
                        if (res) navigate('/approval/forms/pending/' + props.redirectURL);
                    })
            }
        })
    };
    
    const clarifyForm = () => {
        if (!comment) {
            tinyAlert('error', 'Kindly enter a comment!');
            return;
        }

        confirmAlert(`Do you want to send for clarification?`).then((r: any) => {
            if (r.isConfirmed) {
                api.approvalWorkflow(props.formApproverId, props.formName, 4, comment, commentFiles, false, toRoleId, toEmail, FormApprovers)
                    .then(res => {
                        if (res) navigate('/approval/forms/pending/' + props.redirectURL);
                    })
            }
        })
    };
    const reviewForm = () => {
        if (!comment) {
            tinyAlert('error', 'Kindly enter a comment!');
            return;
        }

        confirmAlert(`Do you want to send for a restart?`).then((r: any) => {
            if (r.isConfirmed) {
                api.approvalWorkflow(props.formApproverId, props.formName, 5, comment, commentFiles, false, toRoleId, toEmail, FormApprovers)
                    .then(res => {
                        if (res) navigate('/approval/forms/pending/' + props.redirectURL);
                    })
            }
        })
    };

    const createFormApprovals = (e: FormEvent) => {
        e.preventDefault();

        const mainApproval = props.FormApprovers;

        let cc = [...FormApprovers];
        let oob = { "roleId": toRoleId, "email": toEmail };

        // check if role already exist
        const roleExists =  mainApproval.find((i:any)=> i.roleId === toRoleId);

        //check if email already exist
        const emailExists =  mainApproval.find((i:any)=>  i.email === toEmail);

        if (!!toRoleId && !!toEmail) {
            
            if (!!roleExists) {

                tinyAlert('error', 'Role already exists in the approval sequence');
                return;
            } 
            if (!!roleExists) {

                tinyAlert('error', 'Email already exists in the approval sequence');
                return;
            }

            if (!roleExists && !emailExists) {
                cc.push(oob);

            } else {
                tinyAlert('error', 'Role or Email already exists in the approval sequence');
                return;
            }
            
        } else {
            tinyAlert('error', 'Email address and role are required');
            return;
        }

        setFormApprovers(cc);
        settoEmail('')
        settoRoleId('')

    }

    const removeApproval = (x: any) => {
        let cc = FormApprovers;

        let newcc = cc.filter((item: any) => item.email !== x.email)
        console.log(newcc)
        setFormApprovers(newcc);


    }

    const canExtendForm = (activeApprovals:any, current_person_id:any) => {
        console.log('isee', activeApprovals, current_person_id)

        // if(!!activeApprovals.length){
        //     let current_person = null;
        //     for(let i = 0; i < activeApprovals.length; i++){
        //        if(activeApprovals[i].formApproval.formApproverId === current_person_id){
        //         current_person = activeApprovals[i];
        //         console.log(current_person)
        //         return;
        //        }
        //     }

        //     console.log(current_person)



        //     return true;
        // }
        // else
        //     return false
    }

    return (
        <div>
            {
                !!props.formApproverId &&
                <div className='pt-5'>
                    <div>
                        <label htmlFor="first_name" className="block text-gray-600 font-bold text-md flex">New Comment</label>
                        <hr />
                        <input type='file' multiple onChange={storeFile} className='p-1' />

                        <textarea
                            id="comment"
                            name="comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value.trimStart())}
                            className="border rounded-xs px-3 py-2 w-full text-sm mt-2"
                            placeholder="Enter comment"
                            style={{ height: '150px' }}
                        ></textarea>
                    </div>

                    {
                        !!props.FormApprovers &&
                    <div>

                        {
                            // JSON.stringify(props.FormApprovers)
                        }
                        
                    {
                        (props.FormApprovers[0]?.formApproval?.formApproverId !== props.formApproverId) &&
                        <div className='mb-5 mt-5'>
                            <p>If required, you can extend the approval workflow here.</p>

                            <div className='flex mt-2'>

                                <select onChange={(e) => settoRoleId(e.target.value)} className='p-2 ml-1'>
                                    <option value=''>Select Role</option>
                                    {
                                        allROles.map((item: any, i) => item.canExtendWorkflow && <option key={i} value={item.id}>{item.normalizedName}</option>)
                                    }
                                </select>

                                {/* <input type="email" value={toEmail} onChange={(e) => settoEmail(e.target.value)}  className='p-2 ml-1 w-1/2' placeholder='enter email address' /> */}
                                <Autocomplete
                                    className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                                    filterOptions={filterOptions}
                                    disableClearable={true}
                                    onChange={(v, e: any) => settoEmail(e.value)}
                                    id="AlternateEmailAddress"
                                    options={tepng_users}
                                    value={!!toEmail ? toEmail : ""}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder="Select..."
                                            variant="standard"
                                            {...params}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            style={{
                                                fontFamily: "Nunito",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {getOptionLabel(option)}
                                        </li>
                                    )}
                                />

                                <button type='button' onClick={createFormApprovals} className="w-2/6 ml-1 transition-all px-4 py-2 text-green-600 border border-2 border-green-600 rounded hover:bg-green-600 hover:text-white focus:outline-none font-semibold">Add</button>

                            </div>

                            <div className='mb-5 mt-5'>
                            {
                                FormApprovers.map((item: any, i) =>
                                    <p>{i + 1} {item?.email} - {allROles?.find((x: any) => x?.id === item?.roleId)?.normalizedName}


                                        <button onClick={() => removeApproval(item)} className="ml-5 underline text-red-500 text-sm">Remove</button></p>
                                )
                            }
                            </div>
                        </div>
                    }
                    </div> 
                    }


                    <div className="text-left pb-8 flex gap-4">
                        <button title='This action will approves the form for the next approval workflow' onClick={approveForm} type="button" className="transition-all px-4 py-2 text-green-600 border border-2 border-green-600 rounded w-1/4 hover:bg-green-600 hover:text-white focus:outline-none font-semibold" name='Approve'>
                            Approve
                        </button>
                        <button title='This action will prompt the initiator to start the form all over.' onClick={reviewForm} type="button" className="transition-all px-4 py-2 text-blue-600 border border-2 border-blue-600 rounded w-1/4 hover:bg-blue-600 hover:text-white focus:outline-none font-semibold" name='Restart'>
                            Restart
                        </button>
                        <button title='This action will prompt the initiator to upload additional relevant documents.' onClick={clarifyForm} type="button" className="transition-all px-4 py-2 text-orange-600 border border-2 border-orange-600 rounded w-1/4 hover:bg-orange-600 hover:text-white focus:outline-none font-semibold" name='Clarify'>
                            Clarify
                        </button>
                        <button title='This action will reject the documents.' onClick={rejectForm} type="button" className="transition-all px-4 py-2 text-red-600 border border-2 border-red-600 rounded w-1/4 hover:bg-red-600 hover:text-white focus:outline-none font-semibold" name='Reject'>
                            Reject
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default FormApproval;
