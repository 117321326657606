import React from "react";

function Help() {
  return (
    <div className="h-[85vh] bg-white w-full shadow-sm rounded-lg py-4 notdark:bg-gray-800 flex justify-center align-center ">
      <p className="text-gray-600 mt-[40vh]">No content available at this time</p>
    </div>
  );
}

export default Help;
