import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { LuAlignJustify, LuX } from "react-icons/lu";
import { CloseNav } from "../../interfaces";
import { APP_ENV } from "../../utils";

const SideNav = ({ closeNav }: CloseNav) => {
  const location = useLocation();
  const [isSubNav, setIsSubNav] = useState<boolean>(false);
  const [isMainSubNav, setMainSubNav] = useState<string>("hidden");
  const [subNavLabel, setSubNavLabel] = useState("");
  const [crudAction, setCrudAction] = useState("");

  // const account_type = location.pathname.split("/")[1];
  const activePath = location.pathname.split("/")[2];
  const secondaryPath = location.pathname.split("/")[3];
  const formType = location.pathname.split("/")[4];

  const openSubNav = (label: string, type: string) => {
    setIsSubNav(true);
    setSubNavLabel(label);
    setCrudAction(type);
  };

  const closeSubNav = () => {
    setIsSubNav(false);
  };

  useEffect(() => {
    if (closeNav) setIsSubNav(false);
  }, [closeNav]);

  const SubLink = (to: string, name: string) => (
    <li className="mb-1">
      <Link
        onClick={closeSubNav}
        to={`/initiator/forms/${crudAction}/${to}`}
        className={
          [to].includes(formType)
            ? "bg-[#e0424d] block py-4 px-5 text-left text-[#fff] notdark:hover:bg-[#162c51] notdark:text-gray-300 flex justify-between text-sm"
            : "block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] notdark:hover:bg-[#162c51] notdark:text-gray-300 flex justify-between text-sm"
        }
      >
        <span>{name}</span>
      </Link>
    </li>
  );

  return (
    <div>
      <div className="block sm:hidden">
        <button
          onClick={() =>
            setMainSubNav(isMainSubNav === "hidden" ? "" : "hidden")
          }
          className="bg-gray-300 shadow p-1 left-[15px] top-[15px] fixed z-9999"
        >
          {isMainSubNav === "hidden" ? (
            <LuAlignJustify />
          ) : (
            <LuX className="text-red-600" />
          )}
        </button>
      </div>

      <div className={`sm:flex notdark:bg-[#414b5a] ${isMainSubNav}`}>
        <nav className={`nav-area bg-white border-r border-gray-200 h-screen overflow-hidden notdark:bg-[#414b5a] notdark:border-[#374151] ${APP_ENV !== "prod"  ? 'mt-[30px]':'mt-0' }`}>
          <div className="shadow-lg mb-3 flex justify-center items-center notdark:rounded-[3px] border-[#f6f8fc] border-b">
            <img src="/logo.png" alt="logo_image" className={APP_ENV !== "prod"  ? 'w-[90%]':''} />
          </div>

          <ul className="font-medium mb-5">
            <li className="mb-4 mt-5">
              <Link
                to="/initiator/dashboard"
                className={
                  ["dashboard"].includes(activePath)
                    ? "active block py-3 text-center"
                    : "block py-3 text-center"
                }
              >
                <div className="flex justify-center text-3xl">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                  </svg>
                </div>
                <label className="block px-3">Home</label>
              </Link>
            </li>

            <li className="mb-4">
              <span
                onClick={() => openSubNav("Create New Form", "create")}
                className={
                  (["create", "edit"].includes(secondaryPath) ? "active" : "") +
                  " block py-3 text-center hover:bg-gray-50 cursor-pointer"
                }
              >
                <div className="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Create New Form</label>
              </span>
            </li>

            <li className="">
              <span
                onClick={() => openSubNav("Form History", "history")}
                className={
                  (["history", "details"].includes(secondaryPath)
                    ? "active"
                    : "") +
                  " block py-3 text-center hover:bg-gray-50  cursor-pointer"
                }
              >
                <div className="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">My Form History</label>
              </span>
            </li>
          </ul>
        </nav>

        {isSubNav && (
          <nav className="fixed nav-forms overflow-y-auto shadow-sm notdark:bg-[#414b5a]">
            <div className="modal-container">
              <div
                className="flex justify-between shadow-sm notdark:bg-gray-700 bg-white notdark:text-gray-300 notdark:border-[#474747] border-b border-[#d6d6d6]"
                style={{ position: "fixed", width: "250px", padding: "2px" }}
              >
                <div className="modal-content py-4 text-left px-5 w-full">
                  <div className="flex justify-between">
                    <h2 className="text-sm font-bold mt-9 block uppercase">
                      {subNavLabel}
                    </h2>
                    <button
                      className="text-red-500 text-md font-bold mt-6 block text-end bg-gray-100 p-2 border hover:shadow"
                      onClick={closeSubNav}
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              </div>

              <div className="modal-content mb-4 text-left pb-4 capitalize">
                <ul
                  style={{ paddingTop: "95px" }}
                  className="shadow-sm notdark:bg-gray-700 bg-white notdark:text-gray-300 notdark:border-[#474747]"
                >
                  {SubLink("admin-amendment-form", "admin amendment form")}
                  {SubLink("cpnc-routing-slip", "CPNC routing slip")}
                  {SubLink(
                    "routing-slip-for-tas-contracts",
                    "routing slip for TAS contracts"
                  )}
                  {SubLink(
                    "contract-approval-form-admin-amendment-for-decentralized-purchasing",
                    "contract approval form admin amendment for decentralized purchasing"
                  )}
                  {SubLink("contract-approval-form", "contract approval form")}
                  {SubLink(
                    "routing-slip-for-material-purchase",
                    "routing slip for material purchase"
                  )}
                  {SubLink(
                    "routing-slip-for-deviation-from-standard-template",
                    "routing slip for deviation from standard template"
                  )}
                </ul>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default SideNav;
