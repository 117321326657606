import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { LuAlignJustify, LuX } from "react-icons/lu";
import { CloseNav } from "../../interfaces";
import { APP_ENV } from "../../utils";

const AdminSideNav = ({ closeNav }: CloseNav) => {
  const location = useLocation();
  const [subNavLabel, setSubNavLabel] = useState("");
  const [crudAction, setCrudAction] = useState("");
  const [isSubNav, setIsSubNav] = useState(false);
  const [isMainSubNav, setMainSubNav] = useState("hidden");

  const active_path = location.pathname.split("/")[1];
  const secondary_path = location.pathname.split("/")[2];
  const formType = location.pathname.split("/")[3];

  const openSubNav = (label: string, type: string) => {
    setIsSubNav(true);
    setSubNavLabel(label);
    setCrudAction(type);
  };

  const closeSubNav = () => {
    setIsSubNav(false);
  };

  useEffect(() => {
    if (closeNav) setIsSubNav(false);
  }, [closeNav]);

  const SubLink = (to: string, name: string) => (
    <li className="mb-1">
      <Link
        onClick={closeSubNav}
        to={`/admin/forms/${crudAction}/${to}`}
        className={
          [to].includes(formType)
            ? "bg-[#e0424d] block py-4 px-5 text-left text-[#fff] notdark:hover:bg-[#162c51] notdark:text-gray-300 flex justify-between text-sm"
            : "block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] notdark:hover:bg-[#162c51] notdark:text-gray-300 flex justify-between text-sm"
        }
      >
        <span>{name}</span>
      </Link>
    </li>
  );

  return (
    <div>
      <div className="block sm:hidden">
        {isMainSubNav === "hidden" ? (
          <button
            onClick={() => setMainSubNav("")}
            className="bg-gray-300 shadow p-1 left-[15px] top-[15px]"
            style={{ position: "fixed", zIndex: "9999" }}
          >
            <LuAlignJustify />
          </button>
        ) : (
          <button
            onClick={() => setMainSubNav("hidden")}
            className="bg-red-600 p-1 text-white left-[89px] top-[10px]"
            style={{ position: "fixed", zIndex: "9999" }}
          >
            <LuX />
          </button>
        )}
      </div>

      <div className={`sm:flex notdark:bg-[#414b5a] ${isMainSubNav}`}>
        <nav className={`nav-area bg-white border-r border-gray-200 h-screen overflow-hidden notdark:bg-[#414b5a] notdark:border-[#374151] ${APP_ENV !== "prod"  ? 'mt-[30px]':'mt-0' }`}>
          {/* side nav logo area */}

          <div className="shadow-lg mb-3 flex justify-center items-center notdark:rounded-[3px] border-[#f6f8fc] border-b">
            <img src="/logo.png" alt="logo_image" className={APP_ENV !== "prod"  ? 'w-[90%]':'' } />
          </div>

          {/* side nav - links */}
          <ul className="font-medium mb-5">
            <li className="mb-1">
              <Link
                to="/admin/ms-reporting-and-analytics"
                className={
                  (["ms-reporting-and-analytics"].includes(active_path)
                    ? "active"
                    : "") + " block py-3 text-center"
                }
              >
                <div className="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm96 288H448c17.7 0 32-14.3 32-32V251.8c0-7.6-2.7-15-7.7-20.8l-65.8-76.8c-12.1-14.2-33.7-15-46.9-1.8l-21 21c-10 10-26.4 9.2-35.4-1.6l-39.2-47c-12.6-15.1-35.7-15.4-48.7-.6L135.9 215c-5.1 5.8-7.9 13.3-7.9 21.1v84c0 17.7 14.3 32 32 32z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Report & Analytics</label>
              </Link>
            </li>
            <li className="mb-1">
              <span
                onClick={() => openSubNav("Raised Forms", "raisedforms")}
                className={
                  ([
                    "raisedforms",
                    "approvalsettings",
                    "reassignforms",
                    "manageformcontent",
                  ].includes(secondary_path)
                    ? "active"
                    : "") +
                  " block py-3 text-center hover:bg-gray-50  cursor-pointer"
                }
              >
                <div className="flex justify-center">
                  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 14H11V12H4V14ZM4 10H14V8H4V10ZM4 6H14V4H4V6ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H16V2H2V16Z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3" title="Raised Forms">
                  Forms
                </label>
              </span>
            </li>
            <li className="mb-1">
              <Link
                to="/admin/departments"
                className={
                  (["departments"].includes(active_path) ? "active" : "") +
                  " block py-3 text-center hover:bg-gray-50 "
                }
              >
                <div className="flex justify-center">
                  <svg
                    viewBox="0 0 24 24"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="8.8" y="2.8" width="6.4" height="6.4" rx="1.2" />
                    <rect x="14.8" y="15.8" width="6.4" height="6.4" rx="1.2" />
                    <rect x="2.8" y="15.8" width="6.4" height="6.4" rx="1.2" />
                    <path d="M12 9V12.5M12 12.5H17C17.5523 12.5 18 12.9477 18 13.5V15.5M12 12.5H7C6.44772 12.5 6 12.9477 6 13.5V15.5" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Department</label>
              </Link>
            </li>
            <li className="mb-1">
              <Link
                to="/admin/roles-and-permission"
                className={
                  (["roles-and-permission"].includes(active_path)
                    ? "active"
                    : "") + " block py-3 text-center hover:bg-gray-50 "
                }
              >
                <div className="flex justify-center">
                  <svg
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 15C13.4167 15 13.7708 14.8542 14.0625 14.5625C14.3542 14.2708 14.5 13.9167 14.5 13.5C14.5 13.0833 14.3542 12.7292 14.0625 12.4375C13.7708 12.1458 13.4167 12 13 12C12.5833 12 12.2292 12.1458 11.9375 12.4375C11.6458 12.7292 11.5 13.0833 11.5 13.5C11.5 13.9167 11.6458 14.2708 11.9375 14.5625C12.2292 14.8542 12.5833 15 13 15ZM13 18C13.5167 18 13.9917 17.8792 14.425 17.6375C14.8583 17.3958 15.2083 17.075 15.475 16.675C15.1083 16.4583 14.7167 16.2917 14.3 16.175C13.8833 16.0583 13.45 16 13 16C12.55 16 12.1167 16.0583 11.7 16.175C11.2833 16.2917 10.8917 16.4583 10.525 16.675C10.7917 17.075 11.1417 17.3958 11.575 17.6375C12.0083 17.8792 12.4833 18 13 18ZM8 20C5.68333 19.4167 3.77083 18.0875 2.2625 16.0125C0.754167 13.9375 0 11.6333 0 9.1V3L8 0L16 3V8.675C15.6833 8.54167 15.3583 8.42083 15.025 8.3125C14.6917 8.20417 14.35 8.125 14 8.075V4.4L8 2.15L2 4.4V9.1C2 9.88333 2.10417 10.6667 2.3125 11.45C2.52083 12.2333 2.8125 12.9792 3.1875 13.6875C3.5625 14.3958 4.01667 15.05 4.55 15.65C5.08333 16.25 5.675 16.75 6.325 17.15C6.50833 17.6833 6.75 18.1917 7.05 18.675C7.35 19.1583 7.69167 19.5917 8.075 19.975C8.05833 19.975 8.04583 19.9792 8.0375 19.9875C8.02917 19.9958 8.01667 20 8 20ZM13 20C11.6167 20 10.4375 19.5125 9.4625 18.5375C8.4875 17.5625 8 16.3833 8 15C8 13.6167 8.4875 12.4375 9.4625 11.4625C10.4375 10.4875 11.6167 10 13 10C14.3833 10 15.5625 10.4875 16.5375 11.4625C17.5125 12.4375 18 13.6167 18 15C18 16.3833 17.5125 17.5625 16.5375 18.5375C15.5625 19.5125 14.3833 20 13 20Z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Roles & Permission</label>
              </Link>
            </li>
            <li className="mb-1">
              <Link
                to="/admin/users"
                className={
                  (["users"].includes(active_path) ? "active" : "") +
                  " block py-3 text-center hover:bg-gray-50 "
                }
              >
                <div className="flex justify-center">
                  <svg
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0ZM18 16V13C18 12.2667 17.7958 11.5625 17.3875 10.8875C16.9792 10.2125 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42083 18.05 9.6625C18.8 9.90417 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.2542 21.525 11.6625C21.8417 12.0708 22 12.5167 22 13V16H18ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97917 13.3 7.9375C13.0167 7.89583 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3958 6.675 13.6375 6.025C13.8792 5.375 14 4.7 14 4C14 3.3 13.8792 2.625 13.6375 1.975C13.3958 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116667 13.0667 0.0625 13.3 0.0375C13.5333 0.0125 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9542 12.85 13.8625 12.7C13.7708 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5625 10.775 11.3375C9.85833 11.1125 8.93333 11 8 11C7.06667 11 6.14167 11.1125 5.225 11.3375C4.30833 11.5625 3.4 11.9 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">User Management</label>
              </Link>
            </li>

            <li className="mb-32">
              <Link
                to="/admin/approval-workflows"
                className={
                  ([""].includes(active_path) ? "active" : "") +
                  " block py-3 text-center hover:bg-gray-50 "
                }
              >
                <div className="flex justify-center">
                  <svg
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 15C13.4167 15 13.7708 14.8542 14.0625 14.5625C14.3542 14.2708 14.5 13.9167 14.5 13.5C14.5 13.0833 14.3542 12.7292 14.0625 12.4375C13.7708 12.1458 13.4167 12 13 12C12.5833 12 12.2292 12.1458 11.9375 12.4375C11.6458 12.7292 11.5 13.0833 11.5 13.5C11.5 13.9167 11.6458 14.2708 11.9375 14.5625C12.2292 14.8542 12.5833 15 13 15ZM13 18C13.5167 18 13.9917 17.8792 14.425 17.6375C14.8583 17.3958 15.2083 17.075 15.475 16.675C15.1083 16.4583 14.7167 16.2917 14.3 16.175C13.8833 16.0583 13.45 16 13 16C12.55 16 12.1167 16.0583 11.7 16.175C11.2833 16.2917 10.8917 16.4583 10.525 16.675C10.7917 17.075 11.1417 17.3958 11.575 17.6375C12.0083 17.8792 12.4833 18 13 18ZM8 20C5.68333 19.4167 3.77083 18.0875 2.2625 16.0125C0.754167 13.9375 0 11.6333 0 9.1V3L8 0L16 3V8.675C15.6833 8.54167 15.3583 8.42083 15.025 8.3125C14.6917 8.20417 14.35 8.125 14 8.075V4.4L8 2.15L2 4.4V9.1C2 9.88333 2.10417 10.6667 2.3125 11.45C2.52083 12.2333 2.8125 12.9792 3.1875 13.6875C3.5625 14.3958 4.01667 15.05 4.55 15.65C5.08333 16.25 5.675 16.75 6.325 17.15C6.50833 17.6833 6.75 18.1917 7.05 18.675C7.35 19.1583 7.69167 19.5917 8.075 19.975C8.05833 19.975 8.04583 19.9792 8.0375 19.9875C8.02917 19.9958 8.01667 20 8 20ZM13 20C11.6167 20 10.4375 19.5125 9.4625 18.5375C8.4875 17.5625 8 16.3833 8 15C8 13.6167 8.4875 12.4375 9.4625 11.4625C10.4375 10.4875 11.6167 10 13 10C14.3833 10 15.5625 10.4875 16.5375 11.4625C17.5125 12.4375 18 13.6167 18 15C18 16.3833 17.5125 17.5625 16.5375 18.5375C15.5625 19.5125 14.3833 20 13 20Z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Approval Workflows</label>
              </Link>
            </li>
            <li className="mb-1 hidden">
              <Link
                to="/admin/workflow-thresholds"
                className={
                  ([""].includes(active_path) ? "active" : "") +
                  " block py-3 text-center hover:bg-gray-50 "
                }
              >
                <div className="flex justify-center">
                  <svg
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 15C13.4167 15 13.7708 14.8542 14.0625 14.5625C14.3542 14.2708 14.5 13.9167 14.5 13.5C14.5 13.0833 14.3542 12.7292 14.0625 12.4375C13.7708 12.1458 13.4167 12 13 12C12.5833 12 12.2292 12.1458 11.9375 12.4375C11.6458 12.7292 11.5 13.0833 11.5 13.5C11.5 13.9167 11.6458 14.2708 11.9375 14.5625C12.2292 14.8542 12.5833 15 13 15ZM13 18C13.5167 18 13.9917 17.8792 14.425 17.6375C14.8583 17.3958 15.2083 17.075 15.475 16.675C15.1083 16.4583 14.7167 16.2917 14.3 16.175C13.8833 16.0583 13.45 16 13 16C12.55 16 12.1167 16.0583 11.7 16.175C11.2833 16.2917 10.8917 16.4583 10.525 16.675C10.7917 17.075 11.1417 17.3958 11.575 17.6375C12.0083 17.8792 12.4833 18 13 18ZM8 20C5.68333 19.4167 3.77083 18.0875 2.2625 16.0125C0.754167 13.9375 0 11.6333 0 9.1V3L8 0L16 3V8.675C15.6833 8.54167 15.3583 8.42083 15.025 8.3125C14.6917 8.20417 14.35 8.125 14 8.075V4.4L8 2.15L2 4.4V9.1C2 9.88333 2.10417 10.6667 2.3125 11.45C2.52083 12.2333 2.8125 12.9792 3.1875 13.6875C3.5625 14.3958 4.01667 15.05 4.55 15.65C5.08333 16.25 5.675 16.75 6.325 17.15C6.50833 17.6833 6.75 18.1917 7.05 18.675C7.35 19.1583 7.69167 19.5917 8.075 19.975C8.05833 19.975 8.04583 19.9792 8.0375 19.9875C8.02917 19.9958 8.01667 20 8 20ZM13 20C11.6167 20 10.4375 19.5125 9.4625 18.5375C8.4875 17.5625 8 16.3833 8 15C8 13.6167 8.4875 12.4375 9.4625 11.4625C10.4375 10.4875 11.6167 10 13 10C14.3833 10 15.5625 10.4875 16.5375 11.4625C17.5125 12.4375 18 13.6167 18 15C18 16.3833 17.5125 17.5625 16.5375 18.5375C15.5625 19.5125 14.3833 20 13 20Z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Workflow Thresholds</label>
              </Link>
            </li>
            <li className="mb-32 hidden">
              <Link
                to="/admin/approval-sequence-rules"
                className={
                  ([""].includes(active_path) ? "active" : "") +
                  " block py-3 text-center hover:bg-gray-50 "
                }
              >
                <div className="flex justify-center">
                  <svg
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 15C13.4167 15 13.7708 14.8542 14.0625 14.5625C14.3542 14.2708 14.5 13.9167 14.5 13.5C14.5 13.0833 14.3542 12.7292 14.0625 12.4375C13.7708 12.1458 13.4167 12 13 12C12.5833 12 12.2292 12.1458 11.9375 12.4375C11.6458 12.7292 11.5 13.0833 11.5 13.5C11.5 13.9167 11.6458 14.2708 11.9375 14.5625C12.2292 14.8542 12.5833 15 13 15ZM13 18C13.5167 18 13.9917 17.8792 14.425 17.6375C14.8583 17.3958 15.2083 17.075 15.475 16.675C15.1083 16.4583 14.7167 16.2917 14.3 16.175C13.8833 16.0583 13.45 16 13 16C12.55 16 12.1167 16.0583 11.7 16.175C11.2833 16.2917 10.8917 16.4583 10.525 16.675C10.7917 17.075 11.1417 17.3958 11.575 17.6375C12.0083 17.8792 12.4833 18 13 18ZM8 20C5.68333 19.4167 3.77083 18.0875 2.2625 16.0125C0.754167 13.9375 0 11.6333 0 9.1V3L8 0L16 3V8.675C15.6833 8.54167 15.3583 8.42083 15.025 8.3125C14.6917 8.20417 14.35 8.125 14 8.075V4.4L8 2.15L2 4.4V9.1C2 9.88333 2.10417 10.6667 2.3125 11.45C2.52083 12.2333 2.8125 12.9792 3.1875 13.6875C3.5625 14.3958 4.01667 15.05 4.55 15.65C5.08333 16.25 5.675 16.75 6.325 17.15C6.50833 17.6833 6.75 18.1917 7.05 18.675C7.35 19.1583 7.69167 19.5917 8.075 19.975C8.05833 19.975 8.04583 19.9792 8.0375 19.9875C8.02917 19.9958 8.01667 20 8 20ZM13 20C11.6167 20 10.4375 19.5125 9.4625 18.5375C8.4875 17.5625 8 16.3833 8 15C8 13.6167 8.4875 12.4375 9.4625 11.4625C10.4375 10.4875 11.6167 10 13 10C14.3833 10 15.5625 10.4875 16.5375 11.4625C17.5125 12.4375 18 13.6167 18 15C18 16.3833 17.5125 17.5625 16.5375 18.5375C15.5625 19.5125 14.3833 20 13 20Z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">
                  Approval Sequence Rules
                </label>
              </Link>
            </li>
          </ul>
        </nav>

        {
          /* sub nav section */
          isSubNav && (
            <nav className="fixed nav-forms overflow-y-auto shadow-sm notdark:bg-[#414b5a]">
              <div className="modal-container">
                <div
                  className="flex justify-between shadow-sm notdark:bg-gray-700 bg-white notdark:text-gray-300 notdark:border-[#474747] border-b border-[#d6d6d6]"
                  style={{ position: "fixed", width: "250px", padding: "2px" }}
                >
                  <div className="modal-content py-4 text-left px-5 w-full">
                    <div className="flex justify-between">
                      <h2 className="text-md font-bold mt-6 block">
                        {subNavLabel}
                      </h2>
                      <button
                        className="text-red-500 text-md font-bold mt-6 block text-end"
                        onClick={closeSubNav}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="modal-content mb-4 text-left pb-4 capitalize">
                  <ul
                    style={{ paddingTop: "95px" }}
                    className="shadow-sm notdark:bg-gray-700 bg-white notdark:text-gray-300 notdark:border-[#474747]"
                  >
                    {SubLink("admin-amendment-form", "admin amendment form")}
                    {SubLink("cpnc-routing-slip", "CPNC routing slip")}
                    {SubLink(
                      "routing-slip-for-tas-contracts",
                      "routing slip for TAS contracts"
                    )}
                    {SubLink(
                      "contract-approval-form-admin-amendment-for-decentralized-purchasing",
                      "contract approval form admin amendment for decentralized purchasing"
                    )}
                    {SubLink("contract-approval-form", "contract approval form")}
                    {SubLink(
                      "routing-slip-for-material-purchase",
                      "routing slip for material purchase"
                    )}
                    {SubLink(
                      "routing-slip-for-deviation-from-standard-template",
                      "routing slip for deviation from standard template"
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          )
        }
      </div>
    </div>
  );
};

export default AdminSideNav;
