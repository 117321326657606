import { useState, useEffect, useCallback, FormEvent, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { twoDP, form_input_style, tinyAlert } from "../../../utils";
import {
  OptionProps,
  // FormWorkflow,
  FormApproval,
  FormDetails,
  CPNCRSInputs,
  CPNCRSSelects,
} from "../../../interfaces/forms.interface";
import { User } from "../../../interfaces/users.interface";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import CurrencyInput from "react-currency-input-field";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../services/form.services";
import { XMark } from "../../../components/Icons";
import ApprovalSequenceFlow from "../../../components/ApprovalSequenceFlow";
import FormNotes from "../../../components/FormNotes";
import FormDescription from "../../../components/FormDescription";
import { Label } from "../../../components/FormUI";
import { formTypesID } from "../../../utils/data";
// import form_util from "../../../services/FormUtil.services";
import FormApprovalOverlay from "../../../components/FormApprovalOverlay";
import cpncrs from "../../../services/forms/CPNCRoutingSlipForm.services";

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => (
  <div
    dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : "" }}
  />
);

function CPNCRoutingSlip() {
  // react hooks
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  // useRef variables
  const clickedButtonRef = useRef("");

  // global variables
  const user: User = useSelector((state: any) => state.tepngUser.value.user);
  const formIDQuery: any = params.id;
  const formName: string = location.pathname.split("/")[4];
  const formType: any = formTypesID.find((x: any) => x.name === formName)?.type;

  // const default state value;
  const cpncrsInputs: CPNCRSInputs = {
    ContractTitle: "",
    ContractorsName: "",
    ContractValue: "",
    AmendmentValue: "",
    CumulativeValue: "",
    AgoraReference: "",
    ContractNumber: "",
    PRNumber: "",
    NameOfBuyer: user.firstName + " " + user.lastName,
    AmendmentNo: "",
    AlternateEmailAddress: "",
    OthersDescription: "",
    SavingsAmount: "",
    ReferenceCost: "",
    NewCost: "",
    ContractJustification: "",
    SavingsJustification: "",
  };

  const cpncrsSelects: CPNCRSSelects = {
    currency: { label: "Select...", value: null, name: "currency" },
    department: { label: "Select...", value: null, name: "department" },
    ddq_status: { label: "Select...", value: null, name: "ddq_status" },
    assets: { label: "Select...", value: null, name: "assets" },
    nuprc_status: { label: "Select...", value: null, name: "nuprc_status" },
    human_right: { label: "Select...", value: null, name: "human_right" },
    procurement_method: {
      label: "Select...",
      value: null,
      name: "procurement_method",
    },
    market_intelligence: {
      label: "Select...",
      value: null,
      name: "market_intelligence",
    },
    selected_attachment_type: {
      label: "Select...",
      value: null,
      name: "selected_attachment_type",
    },
    start_date: null,
    end_date: null,
    new_end_date: null,
  };

  // Form constants.
  const [isChecked, setbuttonChecked] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<string>("DRAFT");
  const [parentFormId, setParentFormId] = useState<string>("");

  const [currencies, setCurrencies] = useState<OptionProps[]>([]);
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [ddq_status, setDDQStatus] = useState<OptionProps[]>([]);
  const [assets, setAssets] = useState<OptionProps[]>([]);
  const [nuprc_status, setDPRStatus] = useState<OptionProps[]>([]);
  const [human_right, setHumanRight] = useState<OptionProps[]>([]);
  const [procurement_method, setProcurementMethod] = useState<OptionProps[]>(
    []
  );
  const [yesno_notapplicable, setYesNoNotApplicable] = useState<OptionProps[]>(
    []
  );
  const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
  const [vendors, setVendors] = useState<OptionProps[]>([]);
  const [attachment_type, setAttachmentType] = useState<OptionProps[]>([]);

  const [formDescription, setFormDescription] = useState<FormDetails>();
  // const [form_workflow, setFormWorkflow] = useState<FormWorkflow>();
  const [form_approval, setFormApproval] = useState<FormApproval[]>([]);
  const [formDocuments, setFormDocuments] = useState<any>([]);
  const [removedDocumentIds, setRemovedDocumentIds] = useState<any>([]);
  const [formInputs, setFormInputs] = useState<CPNCRSInputs>(cpncrsInputs);
  const [formSelects, setFormSelects] = useState<CPNCRSSelects>(cpncrsSelects);
  const [togggleModal, setModalTogggle] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<
    string[]
  >([]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    // Set a timer to update the debounced search term after a delay
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(formInputs.ContractValue + formInputs.AmendmentValue);
    }, 7000); // Delay of 500 milliseconds

    // Cleanup function to clear the timer if searchTerm changes before the delay
    return () => {
      clearTimeout(handler);
    };
  }, [formInputs.ContractValue,formInputs.AmendmentValue]);


  // useEffect-hooks-form-details
  useEffect(() => {
    getFormById(formIDQuery, user, navigate);
  }, [formIDQuery, user, navigate]);
  
  useEffect(() => {
    api.fetchFormDescription(formName).then((res) => setFormDescription(res));
  }, [formName]);

  // useEffect-hooks-form-constants
  useEffect(() => {
    api.fetchCurrency().then((res) => setCurrencies(res));
    api.fetchDepartment().then((res) => {
      setDepartment(res);
    });
    api.fetchDdqStatus().then((res) => setDDQStatus(res));
    api.fetchHumanRight().then((res) => setHumanRight(res));
    api.fetchAssets().then((res) => setAssets(res));
    api.fetchDPRStatus().then((res) => setDPRStatus(res));
    api.fetchProcurementMethod().then((res) => setProcurementMethod(res));
    api.fetchYesNoNotApplicable().then((res) => setYesNoNotApplicable(res));
    api.fetchTepngUsers().then((res) => setTepngUsers(res));
    api.fetchVendors().then((res) => setVendors(res));
  }, []);

  useEffect(() => {
    api
      .fetchFormAttachmentTypes(formType)
      .then((res) => setAttachmentType(res));
    // api.fetchFormWorkFlow(formType).then((res) => setFormWorkflow(res));
  }, [formType]);

  useEffect(() => {
    if (!!department.length) {
      let def_department: any = department.find(
        (a: any) => a.value === user.departmentId
      );
      if (!!def_department)
        formSelects.department = def_department;
    }

    
    // eslint-disable-next-line 
  }, [department]);

  // form-workflow-values
  // useEffect(() => {
  //   const data = {
  //     ContractValue: formInputs.ContractValue,
  //     AmendmentValue: formInputs.AmendmentValue,
  //     currency: formSelects.currency,
  //     department: formSelects.department,
  //     form_workflow: form_workflow,
  //   };

  //   form_util.currencyThresholds(data);
  // }, [
  //   formInputs.ContractValue,
  //   formInputs.AmendmentValue,
  //   formSelects.currency,
  //   formSelects.department,
  //   form_workflow,
  // ]);

  // fetch-approval-sequence
  useEffect(() => {
    if (
      debouncedSearchTerm === (formInputs.ContractValue + formInputs.AmendmentValue)
    ) {
      const DepartmentId = formSelects.department.value;
      const ContractValue =
        Number(formInputs.ContractValue) + Number(formInputs.AmendmentValue);
      const Currency = formSelects.currency.value;

      if (!!ContractValue && !!Currency && !!DepartmentId) {
        api
          .fetchApprovalSequence(
            `CPNCRoutingSlipForm/GetApprovalSequence?ContractValue=${ContractValue}&Currency=${Currency}&DepartmentId=${DepartmentId}`
          )
          .then((res) => {
            if (form_approval.length !== res.length) setFormApproval(res);
          });
      }
    }
  }, [
    debouncedSearchTerm,
    formInputs.ContractValue,
    formInputs.AmendmentValue,
    formSelects.department,
    formSelects.currency,
    form_approval,
  ]);

  // form-hanlders
  const handleInput = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInputs({ ...formInputs, [key]: e.target.value });
  };
  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormSelects({ ...formSelects, [fieldName]: selectedOptions });
  };
  const handleDate = (date: any, input: string) => {
    console.log(date)
    setFormSelects({ ...formSelects, [input]: date });
  };

  const handleCurrencyInput = (e: any) => {
    const { value, name } = e.target;
    
    let initial_value = value.replace(/[^0-9.]/g, "");
    let final_value = initial_value.replace(/^(\d*\.)(.*)\./g, "$1$2");

    setFormInputs({ ...formInputs, [name]: final_value });
  };

  const handleFormApproval = (el: any, option: any, item: any) => {
    const new_form_approvals = form_approval.map((i: any) => {
      i.email = i.roleId === item.roleId ? option?.value : i.email;
      return i;
    });
    setFormApproval(new_form_approvals);
  };
  const handleAlternateEmailAddress = (el: any, option: any) => {
    setFormInputs({ ...formInputs, AlternateEmailAddress: option.value });
  };
  const handleContractorsName = (el: any, option: any) => {
    setFormInputs({ ...formInputs, ContractorsName: option.value });
  };

  
  // file-local-upload
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newAttachTypeLabel =
        formSelects.selected_attachment_type.label === "Others"
          ? formInputs.OthersDescription
          : formSelects.selected_attachment_type.label;

      let uploadType = uploadedFilesAttachType;

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadType.push(newAttachTypeLabel);
      }

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...acceptedFiles,
      ]);
      setUploadedFilesAttachType(uploadType);
    },
    [
      formSelects.selected_attachment_type.label,
      formInputs.OthersDescription,
      uploadedFilesAttachType,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // remove attached file.
  const removeFile = (index: number) => {
    const updatedNames = [...uploadedFilesAttachType];
    updatedNames.splice(index, 1);

    const updated_files = [...uploadedFiles];
    updated_files.splice(index, 1);

    setUploadedFiles(updated_files);
    setUploadedFilesAttachType(updatedNames);
  };

  // remove attached file - when update
  const removePrevFile = (index: number) => {
    const updated_files = [...formDocuments];
    const deletedFile = updated_files.splice(index, 1)[0];
    setFormDocuments(updated_files);

    console.log(deletedFile);

    const removed_files = [...removedDocumentIds];
    const append_removed_files = removed_files.push(removedDocumentIds);
    setRemovedDocumentIds(append_removed_files);
  };

  // component-handlers
  const toggleUploadCardModal = () => {
    if (togggleModal) setModalTogggle(false);
    else setModalTogggle(true);
  };

  // [1] create-cpncrs-form-api-services
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const data = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      IsDraft: clickedButtonRef?.current === "post" ? false : true,
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      user: user,
    };

    try {
      const res = await cpncrs.setForm(data);
      if (res?.id) navigate("/initiator/forms/history/cpnc-routing-slip");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // [2] cpncrs-form-api-services
  const handleUpdate = async (e: FormEvent, action: string) => {
    e.preventDefault();

    const data = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      // IsDraft: clickedButtonRef?.current === "post" ? false : true,
      IsDraft: action === "update_and_submit" ? false : true,
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      removedDocumentIds: removedDocumentIds,
      formIDQuery: formIDQuery,
      user: user,
    };

    try {
      const res = await cpncrs.updateForm(data);
      if (res?.id) {
        navigate("/initiator/forms/history/cpnc-routing-slip");
      }

      // if (action === "update_and_submit") {
      //     const submitRes = await cpncrs.submitDraft(formIDQuery);
      //     if (submitRes?.id) {
      //         navigate("/forms/history/cpnc-routing-slip");
      //     }
      // }
      // else {
      // }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // handleReview
  const handleReview = (e: FormEvent) => {
    e.preventDefault();

    const data = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      uploadedFiles: uploadedFiles,
      ReviewDocumentIds: formDocuments,
      uploadedFilesAttachType: uploadedFilesAttachType,
      removedDocumentIds: removedDocumentIds,
      formIDQuery: formIDQuery,
      parentFormId: !!parentFormId ? parentFormId : formIDQuery,
      user: user,
    };

    cpncrs.reviewForm(data).then((res: any) => {
      if (!!res?.id) navigate("/initiator/forms/history/cpnc-routing-slip");
    });
  };

  //getFormById
  const getFormById = (formIDQuery: string, user: User, navigate: any) => {
    if (formIDQuery) {
      api.fetchFormById("CPNCRoutingSlipForm/" + formIDQuery).then((res) => {
        setFormInputs({
          ContractTitle: res.contractTitle,
          ContractorsName: res.contractorsName,
          ContractValue: res.contractValue,
          AmendmentValue: res.amendmentValue,
          CumulativeValue: "",
          AgoraReference: res.agoraReference,
          ContractNumber: res.contractNumber,
          PRNumber: res.prNumber,
          NameOfBuyer: user.firstName + " " + user.lastName,
          AmendmentNo: res.amendmentNo,
          AlternateEmailAddress: res.alternateEmailAddress,
          OthersDescription: res.othersDescription,
          SavingsAmount: res.savingsAmount,
          ReferenceCost: res.referenceCost,
          NewCost: res.newCost,
          ContractJustification: res.contractJustification,
          SavingsJustification: res.savingsJustification,
        });
        setFormSelects({
          currency: {
            label: res.currencyDetails.value,
            value: res.currencyDetails.id,
            name: "currency",
          },
          department: {
            label: res.department.name,
            value: res.department.id,
            name: "department",
          },
          ddq_status: {
            label: res.ddqStatusDetails.name,
            value: res.ddqStatusDetails.id,
            name: "ddq_status",
          },
          human_right: {
            label: res.humanRightsDetails?.name,
            value: res.humanRightsDetails?.id,
            name: "human_right",
          },
          assets: {
            label: res.assetDetails.name,
            value: res.assetDetails.id,
            name: "assets",
          },
          nuprc_status: {
            label: res.nuprcStatusDetails.name,
            value: res.nuprcStatusDetails.id,
            name: "nuprc_status",
          },
          procurement_method: {
            label: res.procurementMethodDetails.name,
            value: res.procurementMethodDetails.id,
            name: "procurement_method",
          },
          market_intelligence: {
            label: res.marketIntelligenceDetails.name,
            value: res.marketIntelligenceDetails.id,
            name: "market_intelligence",
          },
          selected_attachment_type: {
            label: "Select...",
            value: "",
            name: "selected_attachment_type",
          },
          start_date: new Date(res.startDate),
          end_date: new Date(res.endDate),
          new_end_date: res.newDate === null ? null : new Date(res.newDate),
        });

        const approval_sort = res.formApprovers.sort(
          (a: any, b: any) => a.step - b.step
        );
        const approvals: any[] = approval_sort.map((i: any) => ({
          email: i.email,
          roleId: i.roleId,
          roleName: i.role.name,
        }));

        // let currentStatus = ''
        // for(let i = 0;  i < approval_sort.length; i++){
        //     if(approval_sort[i].formApproval){
        //         currentStatus = approval_sort[i]?.formApproval?.status
        //     }

        // }

        // console.log(currentStatus);
        // const currentApproval = currentStatus = approval_sort[0]?.formApproval?.status;

        // if((currentStatus !== 'RESTART' && currentStatus !== 'PENDING' && currentStatus !== '') || (currentApproval === 'APPROVED')){
        //     console.log('i see')
        //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
        //     navigate("/forms/history/cpnc-routing-slip");
        //     return;
        // }

        // if(res.currentStatus !== 'RESTART' && res.currentStatus !== 'DRAFT' && res.currentStatus !== 'PENDING'){
        //     console.log('i called')
        // }

        if (res.canEditOrDelete !== true && res.currentStatus !== "RESTART") {
          tinyAlert(
            "error",
            "Form cannot be edited because the first level approver has acted on it"
          );
          navigate("/initiator/forms/history/cpnc-routing-slip");
          return;
        }

        setFormApproval(approvals);
        setFormDocuments(res.documents);
        setCurrentStatus(res.currentStatus);
        setParentFormId(res.parentFormId);
      });
    }
  };
 
  // ui-component
  return (
    <div className="bg-white w-full shadow-sm rounded-lg mb-12">
      <FormDescription data={formDescription} />

      <form
        onSubmit={handleSubmit}
        className="max-w-1xl mx-auto rounded space-y-2 px-8 mt-8"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3">
          <div className="col-span-full">
            <Label title="Contract Title" for="ContractTitle" required />
            <input
              type="text"
              id="ContractTitle"
              value={formInputs.ContractTitle}
              onChange={(e) => handleInput("ContractTitle", e)}
              className={form_input_style}
              placeholder="Contract Title"
              required
            />
          </div>

          <div>
            <Label title="Contractor's Name" required />
            <Autocomplete
              className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
              filterOptions={filterOptions}
              disableClearable={true}
              onChange={(e, v) => handleContractorsName(e, v)}
              id="AlternateEmailAddress"
              options={vendors}
              value={formInputs.ContractorsName}
              renderInput={(params) => (
                <TextField
                  placeholder="Select..."
                  variant="standard"
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {getOptionLabel(option)}
                </li>
              )}
            />
          </div>

          {/* <div>
            <Label title="Contractor's Name" for="ContractorsName" required />
            <input
              type="text"
              id="ContractorsName"
              value={formInputs.ContractorsName}
              onChange={(e) => handleInput("ContractorsName", e)}
              className={form_input_style}
              placeholder="Contractor's Name"
              required
            />
          </div> */}

          <div>
            <Label title="Currency" for="currency" required />
            <Select
              id="currency"
              value={formSelects.currency}
              options={currencies}
              isMulti={false}
              isSearchable={false}
              onChange={(e) => handleSelect("currency", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Contract Value" for="ContractValue" required />
            <CurrencyInput
              id="ContractValue"
              name="ContractValue"
              value={formInputs.ContractValue}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Contract value"
            />
          </div>

          <div>
            <Label title="Amendment Value" for="AmendmentValue" />
            <CurrencyInput
              id="AmendmentValue"
              name="AmendmentValue"
              value={formInputs.AmendmentValue}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Amendment value"
            />
          </div>

          <div>
            <Label title="New Value (Cumulative) <small className='text-xs'>Initial Value + Amendment Value</small>" />
            <span className="border rounded-xs px-3 py-2 w-full font-normal block span-input input">
              {!!formSelects.currency.value && formSelects.currency.label}{" "}
              &nbsp;
              {twoDP(
                Number(formInputs.ContractValue) +
                  Number(formInputs.AmendmentValue)
              )}
            </span>
          </div>

          <div>
            <Label title="Department/Entity" for="department" required />
            <Select
              id="department"
              value={formSelects.department}
              options={department}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("department", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Agora Ref" required />
            <input
              id="AgoraReference"
              type="text"
              value={formInputs.AgoraReference}
              onChange={(e) => handleInput("AgoraReference", e)}
              className={form_input_style}
              placeholder="Agora Ref"
              required
            />
          </div>

          <div>
            <Label title="DDQ Status" required />
            <Select
              id="ddq_status"
              value={formSelects.ddq_status}
              options={ddq_status}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("ddq_status", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label
              title={`Human Rights`}
              info={
                "Not Applicable can be selected by CE /Buyer if the contract is below CPSC threshold, no report on HR Audit from Ethics and there is no pending case on HR for the Contractor"
              }
              required
            />
            <Select
              id="ddq_status"
              value={formSelects.human_right}
              options={human_right}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("human_right", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Asset" required />
            <Select
              id="assets"
              value={formSelects.assets}
              options={assets}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("assets", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Start Date" required />
            <DatePicker
              id="start_date"
              className="border rounded px-3 py-2 w-full font-normal input"
              selected={formSelects.start_date}
              onChange={(date) => handleDate(date, "start_date")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              required={true}
            />
          </div>

          <div>
            <Label title="End Date" required />
            <DatePicker
              id="end_date"
              className="border rounded px-3 py-2 w-full font-normal input"
              selected={formSelects.end_date}
              onChange={(date) => handleDate(date, "end_date")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              required
            />
          </div>

          <div>
            <Label title="New End Date" />
            <DatePicker
              id="new_end_date"
              className="border rounded px-3 py-2 w-full font-normal input"
              selected={formSelects.new_end_date}
              onChange={(date) => handleDate(date, "new_end_date")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
            />
          </div>

          <div>
            <Label title="NUPRC Status" required />
            <Select
              id="nuprc_status"
              value={formSelects.nuprc_status}
              options={nuprc_status}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("nuprc_status", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Procurement Method" required />
            <Select
              id="procurement_method"
              value={formSelects.procurement_method}
              options={procurement_method}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("procurement_method", e)}
              className="font-normal"
              placeholder="Select..."
              required
            />
          </div>

          <div>
            <Label title="Market Intelligence" />
            <Select
              id="market_intelligence"
              value={formSelects.market_intelligence}
              options={yesno_notapplicable}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("market_intelligence", e)}
              className="font-normal"
              placeholder="Select..."
            />
          </div>

          <div>
            <Label title="Contract/PO No" />
            <input
              id="ContractNumber"
              type="text"
              value={formInputs.ContractNumber.toString().replace(
                /[^0-9]/g,
                ""
              )}
              onChange={(e) => handleInput("ContractNumber", e)}
              className={form_input_style}
              placeholder="Contract/PO No"
              maxLength={10}
              minLength={10}
            />
          </div>

          <div>
            <Label title="PR Number" />
            <input
              id="PRNumber"
              type="text"
              value={formInputs.PRNumber.toString().replace(/[^0-9]/g, "")}
              onChange={(e) => handleInput("PRNumber", e)}
              className={form_input_style}
              placeholder="PR Number"
              maxLength={8}
              minLength={8}
            />
          </div>

          <div>
            <Label title="Amendment No" />
            <input
              id="AmendmentNo"
              type="text"
              value={formInputs.AmendmentNo.toString().replace(/[^0-9]/g, "")}
              onChange={(e) => handleInput("AmendmentNo", e)}
              className={form_input_style}
              placeholder="Amendment No"
            />
          </div>

          <div>
            <Label title="Alternative Email Address" />
            <Autocomplete
              className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
              filterOptions={filterOptions}
              disableClearable={true}
              onChange={(e, v) => handleAlternateEmailAddress(e, v)}
              id="AlternateEmailAddress"
              options={tepng_users}
              value={formInputs.AlternateEmailAddress}
              renderInput={(params) => (
                <TextField
                  placeholder="Select..."
                  variant="standard"
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {getOptionLabel(option)}
                </li>
              )}
            />
          </div>

          <div>
            <Label title="New Cost" for="NewCost" required />
            <CurrencyInput
              id="NewCost"
              name="NewCost"
              value={formInputs.NewCost}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="New Cost"
              required
            />
          </div>

          <div>
            <Label title="Reference Cost" for="ReferenceCost" required />
            <CurrencyInput
              id="ReferenceCost"
              name="ReferenceCost"
              value={formInputs.ReferenceCost}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Reference Cost"
              required
            />
          </div>

          <div>
            <Label title="Savings Amount" for="SavingsAmount" required />
            <CurrencyInput
              id="SavingsAmount"
              name="SavingsAmount"
              value={
                Number(formInputs?.NewCost) - Number(formInputs?.ReferenceCost)
              }
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Savings Amount"
              required
              disabled
            />
          </div>

          <div>
            <Label title="Name of Buyer/CE" />
            <input
              id="NameOfBuyer"
              type="text"
              value={formInputs.NameOfBuyer}
              onChange={(e) => handleInput("NameOfBuyer", e)}
              className={form_input_style}
              placeholder="Name of Buyer/CE"
              disabled
            />
          </div>

          <div>
            <Label title="Contract Justification" required />
            <textarea
              id="ContractJustification"
              value={formInputs.ContractJustification}
              onChange={(e) => handleInput("ContractJustification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Contract Justification"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div>

          <div>
            <Label
              title="Savings Justification / Calculation Method"
              required
            />
            <textarea
              id="SavingsJustification"
              value={formInputs.SavingsJustification}
              onChange={(e) => handleInput("SavingsJustification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Savings Justification / Calculation Method"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div>

          <div>
            <button
              onClick={toggleUploadCardModal}
              type="button"
              className={`bg-gray-50 hover:bg-blue-50 font-[700] border border-gray-300 rounded-xs px-3 py-2 w-full text-sm`}
            >
              Upload Attachment
            </button>
          </div>
        </div>

        {/* attach-document-area */}
        <div
          className={`container mx-auto ${
            !!formDocuments.length || !!uploadedFiles.length ? "" : "hidden"
          }`}
        >
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Attachment Category
                  </th>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Attached File
                  </th>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {formDocuments.length > 0 &&
                  formDocuments.map((item: any, i: any) => (
                    <tr
                      key={i}
                      className="transition-all hover:bg-gray-100 border"
                    >
                      <td className="px-3 py-2  text-xs">
                        {item.attachmentType.name}
                      </td>
                      <td className="px-3 py-2  text-xs">{item.name}</td>
                      <td className="px-3 py-2  text-xs">
                        <button
                          type="button"
                          className="flex"
                          onClick={() => removePrevFile(i)}
                        >
                          <span style={{ marginTop: "2px" }}>
                            <XMark />
                          </span>
                          <span className="ml-1">Remove</span>
                        </button>
                      </td>
                    </tr>
                  ))}

                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((item, i) => (
                    <tr
                      key={i}
                      className="transition-all hover:bg-gray-100 border"
                    >
                      <td className="px-3 py-2  text-xs">
                        {uploadedFilesAttachType[i]}
                      </td>
                      <td className="px-3 py-2  text-xs">{item.name}</td>
                      <td className="px-3 py-2  text-xs">
                        <button
                          type="button"
                          className="flex"
                          onClick={() => removeFile(i)}
                        >
                          <span style={{ marginTop: "2px" }}>
                            <XMark />
                          </span>
                          <span className="ml-1">Remove</span>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* approval personnel */}
        <FormApprovalOverlay data={form_approval} />

        {!!form_approval.length && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 pb-9">
            {form_approval?.map((item: FormApproval, i) => {
              return (
                <div key={i}>
                  <label className="block text-gray-600 font-medium text-sm flex">
                    {item.roleName}
                  </label>
                  <Autocomplete
                    className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                    filterOptions={filterOptions}
                    disableClearable={true}
                    onChange={(e, option) =>
                      handleFormApproval(e, option, item)
                    }
                    id="combo-box-demo"
                    options={tepng_users}
                    value={!!item.email ? item.email : ""}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select..."
                        variant="standard"
                        {...params}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          fontFamily: "Nunito",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {getOptionLabel(option)}
                      </li>
                    )}
                  />
                </div>
              );
            })}
          </div>
        )}

        {
          // approval-workflow-diagram
          !!form_approval.length && (
            <>
              <Label title="Approval Sequence" for="ApprovalSequence" />
              <ApprovalSequenceFlow data={form_approval} />
            </>
          )
        }

        <div className="mx-auto w-2/4">
          {(!formIDQuery || currentStatus === "DRAFT") && (
            <div className="flex pt-12 pb-3">
              <input
                type="checkbox"
                name="isChecked"
                id="validationCheck"
                onChange={(e) => setbuttonChecked(e.target.checked)}
                style={{ width: "20px" }}
              />
              <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                I validate that this form is ready to be routed{" "}
              </label>
            </div>
          )}

          {!formIDQuery ? (
            <div className="flex gap-4 justify-start">
              <button
                type="submit"
                disabled={!!isChecked}
                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                name="save"
                onClick={(e) => (clickedButtonRef.current = "save")}
              >
                Save as Draft
              </button>

              <button
                type="submit"
                disabled={!isChecked}
                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                name="post"
                onClick={(e) => (clickedButtonRef.current = "post")}
              >
                Submit Form
              </button>
            </div>
          ) : (
            <div className="flex gap-4 pt-3 justify-start">
              <button
                type="button"
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm ${
                  currentStatus !== "RESTART" ? "w-full" : "hidden"
                }`}
                name="update"
                disabled={!!isChecked}
                // onClick={(e) => handleUpdate(e, "update_only")}

                onClick={(e) => handleUpdate(e, currentStatus === "DRAFT" ? "update_only" : "update_and_submit")}
              >
                {currentStatus === "DRAFT" ? "Save As Draft" : "Update Form"}
              </button>

              <button
                type="button"
                disabled={!isChecked}
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${
                  currentStatus === "DRAFT" ? "" : "hidden"
                }`}
                name="post"
                onClick={(e) => handleUpdate(e, "update_and_submit")}
              >
                Submit Form
              </button>

              <button
                type="button"
                disabled={!!isChecked}
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${
                  currentStatus === "RESTART" ? "" : "hidden"
                }`}
                name="post"
                onClick={handleReview}
              >
                Submit Restart
              </button>
            </div>
          )}
        </div>
      </form>

      {/* form-footer-note */}
      <FormNotes data={formDescription} />

      {/* Uplooad modal - //!!! make a child component and pass data to parent */}
      {togggleModal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ background: "rgb(0 0 0 / 52%)" }}
        >
          <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="flex justify-between">
              <div className="modal-content py-4 text-left px-6">
                <h2 className="text-2xl font-bold">Attach File</h2>
                <p className="text-xs">Kindly upload your attachment</p>
              </div>
              <div className="modal-content py-4 text-left px-6">
                <button
                  onClick={toggleUploadCardModal}
                  className="p-2"
                  title="Close Modal"
                >
                  <XMark />
                </button>
              </div>
            </div>

            <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
              <div className="mb-3">
                <label className="block text-gray-700 font-bold">
                  Select Attachment to Upload
                </label>
                <Select
                  value={formSelects.selected_attachment_type}
                  options={attachment_type}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleSelect("selected_attachment_type", e)}
                  className="text-sm"
                  placeholder="Select..."
                />
                {formSelects.selected_attachment_type.label === "Others" && (
                  <input
                    id="OthersDescription"
                    type="text"
                    value={formInputs.OthersDescription}
                    onChange={(e) => handleInput("OthersDescription", e)}
                    className={form_input_style}
                    placeholder="Enter description"
                    required
                  />
                )}
              </div>

              <div>
                <div
                  style={{
                    display: !formSelects.selected_attachment_type.value
                      ? "none"
                      : formSelects.selected_attachment_type.label ===
                          "Others" && !formInputs.OthersDescription
                      ? "none"
                      : "block",
                  }}
                  {...getRootProps()}
                  className="dropzoneStyle"
                >
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                {uploadedFiles.length > 0 && (
                  <div className="container mx-auto mt-3">
                    <div className="overflow-x-auto">
                      <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Sn
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Attachment Category
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Attached File
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedFiles.map((item, i) => (
                            <tr
                              key={i}
                              className="transition-all hover:bg-gray-100 border"
                            >
                              <td className="px-3 py-2  text-xs">{i + 1}</td>
                              <td className="px-3 py-2  text-xs">
                                {uploadedFilesAttachType[i]}
                              </td>
                              <td className="px-3 py-2  text-xs">
                                {item.name}
                              </td>
                              <td className="px-3 py-2  text-xs">
                                <button
                                  className="flex"
                                  onClick={() => removeFile(i)}
                                >
                                  <span style={{ marginTop: "2px" }}>
                                    <XMark />
                                  </span>
                                  <span className="ml-1">Remove</span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CPNCRoutingSlip;
/**
 * path: /forms/create/cpnc-routing-slip
 * path: /forms/edit/cpnc-routing-slip
 * note: component-view to edit and create
 * last-refactored: 28/02/2024
 */
